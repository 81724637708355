import logo from '@boomrank/web-assets/public/images/logo/boomrank_450x200.png';
import {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {AccessTokenSuccess, JwtToken, JwtTokenFactory, PlaceHolder, StorageJwtToken} from "@boomrank/react-components";
import {AuthApi} from "../../services/auth/api";
import {AuthLoginForm} from "../forms/login";
import {getTranslation} from "../../intl";

interface Props {
    token: JwtToken
    onLogin: (token: JwtToken) => void
}

export function AuthLoginView(props: Props) {
    let storage = new StorageJwtToken(process.env.REACT_APP_KEY_STORAGE!)

    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    let navigate = useNavigate();
    let location = useLocation();

    let authenticationByUrl = () => {
        let searchParams = new URLSearchParams(location.search);
        if (searchParams.has('at') && searchParams.has('rt') && searchParams.has('ttl')) {
            let token = JwtTokenFactory.fromFragment({
                ttl: parseInt(searchParams.get('ttl') as string),
                access: searchParams.get('at') as string,
                refresh: searchParams.get('rt') as string
            })
            storage.set(token)
            props.onLogin(token)
            navigate('/dashboard/profile/password/', {replace: true});
        }
    }

    let onSubmit = (login: string, password: string) => {
        setError('')
        setIsLoading(true)
        AuthApi.getAccessToken(login, password).then((response)=> {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                let r = response as AccessTokenSuccess
                let token = JwtTokenFactory.fromFragment(r.data)
                storage.set(token)
                props.onLogin(token)

                let searchParams = new URLSearchParams(location.search)
                if (searchParams.has('next')) {
                    navigate(decodeURIComponent(searchParams.get('next') as string))
                } else {
                    navigate('/dashboard/', {replace: true})
                }
            } else  {
                setError(
                    getTranslation('VIEW.LOGIN.ERROR')
                )
            }
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (props.token) {
            let today = new Date();
            if (today < props.token.expired_at) {
                navigate('/dashboard/')
            }
        }
        authenticationByUrl();
    }, []);

    return (
        <>
            <div className={'p-6'}>
                <img src={logo} alt={'digimood'} className={'mx-auto w-1/2'} />
                <span className={'text-br-blue text-base'}>
                    {getTranslation('VIEW.LOGIN.HEADLINE')}
                </span>
            </div>
            {
                isLoading &&
                <PlaceHolder className={'mx-8 h-6'} />
            }
            {
                error &&
                <div role={'alert'} className={'mx-8 text-br-red'}>
                    {error}
                </div>
            }
            <AuthLoginForm onSubmit={onSubmit} disabled={isLoading}/>
        </>
    )
}
