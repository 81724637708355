import {endpoints} from "../api";
import {
    ServiceListResponse,
    ServiceRetrieveResponse
} from "./responses";
import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";

export class ServiceApi {
    static list(token: JwtToken, baseUrl?: string): Promise<FormErrorResponse | ServiceListResponse> {
        let url = endpoints.service.list;
        if (baseUrl) {
            url = baseUrl;
        }
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static retrieve(token: JwtToken, id: number): Promise<FormErrorResponse | ServiceRetrieveResponse> {
        let url = endpoints.service.retrieve.replace(':pk', id.toString());
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }
}