import {ServiceFragment} from "./fragments";
import {Service} from "../../models";

export class ServiceFactory {
    static fromFragment = (fragment: ServiceFragment): Service => {
        let service = new Service()
        service.id = fragment.id
        service.logo = fragment.logo
        service.url = fragment.url
        service.description = fragment.description
        service.clientId = fragment.client_id
        service.name = fragment.name
        return service
    }
}