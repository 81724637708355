import {AuthAuthorizeView} from "../views/authorize";
import {JwtToken} from "@boomrank/react-components";
import {getTranslation} from "../../intl";

interface Props {
    token: JwtToken
}

export function AuthAuthorizeRoute(props: Props) {
    document.title = `${getTranslation('ROUTE.AUTH.AUTHORIZE.TITLE')} | Boomrank.io`

    return (
        <AuthAuthorizeView token={props.token} />
    )
}