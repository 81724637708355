import {AuthApi} from "../../../services/auth/api";
import {JwtToken} from "@boomrank/react-components";
import {useState} from "react";
import {DashboardProfileChangePasswordForm} from "../../forms/profile/change-password";
import {getTranslation} from "../../../intl";

interface Props {
    token: JwtToken
}

export function DashboardProfileChangePasswordView(props: Props) {
    let [isLoading, setIsLoading] = useState(false)
    let [isSubmitted, setIsSubmitted] = useState(false)
    let [error, setError] = useState('')
    let [success, setSuccess] = useState('')

    let onSubmit = (password: string) => {
        setIsLoading(true)
        setSuccess('')
        setError('')

        AuthApi.changePassword(props.token, password)
            .then((response) => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    setIsSubmitted(true)
                    setSuccess(
                        getTranslation('VIEW.PROFILE.CHANGE_PASSWORD.SUCCESS')
                    )
                } else {
                    setError(
                        getTranslation('VIEW.PROFILE.CHANGE_PASSWORD.ERROR')
                    )
                }
                setIsLoading(false)
            })
    }

    return (
        <div>
            {
                error &&
                <div role={'alert'} className={'p-6 text-br-red'}>
                    {error}
                </div>
            }
            {
                success &&
                <div role={'alert'} className={'p-6 text-br-green'}>
                    {success}
                </div>
            }
            <DashboardProfileChangePasswordForm onSubmit={onSubmit} disabled={isLoading || isSubmitted} />
        </div>
    )
}