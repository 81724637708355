import {UserFragment} from "./fragments";
import {User} from "../../models";

export class UserFactory{
    static fromFragment = (fragment: UserFragment): User => {
        let user = new User()
        user.id = fragment.id
        user.email = fragment.email
        user.firstname = fragment.firstname
        user.lastname = fragment.lastname
        user.isAdmin = fragment.is_admin
        user.isActive = fragment.is_active
        return user
    }
}