import React, {useState} from "react";
import {Identifier, Me} from "../../../models";
import {JwtToken} from "@boomrank/react-components";
import {UserApi} from "../../../services/user/api";
import {UserUpdateResponse} from "../../../services/user/responses";
import {AccountApi} from "../../../services/account/api";
import {UserFactory} from "../../../services/user/factory";
import {AccountUpdateResponse} from "../../../services/account/responses";
import {AccountFactory} from "../../../services/account/factory";
import {DashboardProfileEditForm} from "../../forms/profile/edit";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    token: JwtToken
    cacheUpdater: (obj: Identifier) => void
}

export function DashboardProfileEditView(props: Props) {
    let [isLoading, setIsLoading] = useState(false)
    let [error, setError] = useState('')
    let [success, setSuccess] = useState('')

    let onSubmit = (me: Me) => {
        setIsLoading(true)
        setError('')
        setSuccess('')

        UserApi.update(
            props.token,
            me.user
        ).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as UserUpdateResponse;
                let user = UserFactory.fromFragment(response.data);

                let account = {
                    ...me,
                    company: me.company.id,
                    user: me.user.id
                }
                AccountApi.update(
                    props.token,
                    user,
                    account
                ).then((accountResponse) => {
                    if (accountResponse.statusCode >= 200 && accountResponse.statusCode < 300) {
                        accountResponse = accountResponse as AccountUpdateResponse
                        let account = AccountFactory.fromFragment(accountResponse.data)
                        props.cacheUpdater(user)
                        props.cacheUpdater(account)
                        setSuccess(
                            getTranslation('VIEW.PROFILE.EDIT.SUCCESS')
                        )
                    } else {
                        setError(
                            getTranslation('VIEW.PROFILE.EDIT.ERROR')
                        )
                    }
                    setIsLoading(false)
                })
            } else {
                setError(
                    getTranslation('VIEW.PROFILE.EDIT.ERROR')
                )
                setIsLoading(false)
            }
        })
    }

    return (
        <div>
            {
                error &&
                <div role={'alert'} className={'p-6 text-br-red border border-br-red bg-red-50 mt-3'}>
                    {error}
                </div>
            }
            {
                success &&
                <div role={'alert'} className={'p-6 text-br-green border border-br-green bg-green-50 mt-3'}>
                    {success}
                </div>
            }
            <DashboardProfileEditForm onSubmit={onSubmit} disabled={isLoading}  me={props.me}/>
        </div>
    )
}
