import {Account, Company, Identifier, Me, Service, User} from "../../../models";
import {Breadcrumb, Cache, JwtToken, PlaceHolder} from "@boomrank/react-components";
import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {DashboardCompanyEditView} from "../../views/company/edit";
import {useParams} from "react-router";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    token: JwtToken
    accounts: Account[]
    services: Service[]
    cacheUsers: Cache<User>
    cacheCompanies: Cache<Company>
    cacheUpdater: (obj: Identifier) => void
}

export function DashboardCompanyEditRoute(props: Props) {
    let location = useLocation()
    let params = useParams()
    let [company, setCompany] = useState<Company | null | undefined>(undefined)

    useEffect(() => {
        document.title = ` | Boomrank.io`
        if (params.companyId) {
            let companies = props.cacheCompanies.get([params.companyId])
            if (companies.length > 0) {
                setCompany(companies[0])
                document.title = `${companies[0].name} | Boomrank.io`

            } else {
                setCompany(null)
            }
        }
    }, [params.companyId, props.cacheCompanies])

    if (company === null) {
        return <>Error</>
    }
    if (company === undefined) {
        return <PlaceHolder className={'h-32'} />
    }

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content:<i className={'fa-solid fa-home'}/>, title: getTranslation('BREADCRUMB.HOMEPAGE')},
                    {to: '/dashboard/companies/', content: getTranslation('BREADCRUMB.COMPANIES')},
                    {to: `/dashboard/companies/edit/${company.id}/`, content: <>{company.name}</>},
                ]}
            />
            <div className={'mt-3'}>
                <DashboardCompanyEditView
                    accounts={props.accounts}
                    company={company}
                    me={props.me}
                    cacheUsers={props.cacheUsers}
                    services={props.services}
                    cacheUpdater={props.cacheUpdater}
                    token={props.token}
                />
            </div>
        </>
    )
}