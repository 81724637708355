import {JwtToken, PlaceHolder, Cache} from "@boomrank/react-components";
import {Company, Identifier, Me, Service, User, Account} from "../../../models";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {CompanyApi} from "../../../services/company/api";
import {CompanyCreateResponse} from "../../../services/company/responses";
import {CompanyFactory} from "../../../services/company/factory";
import {DashboardCompanyForm} from "../../forms/company";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    token: JwtToken
    accounts: Account[]
    services: Service[]
    cacheUsers: Cache<User>
    cacheUpdater: (obj: Identifier) => void
}

export function DashboardCompanyAddView(props: Props) {
    let navigate = useNavigate()
    let [isLoading, setIsLoading] = useState(false)
    let [error, setError] = useState('')

    let onSubmit = (company: Company) => {
        setIsLoading(true)
        setError('')

        CompanyApi.create(props.token, company).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as CompanyCreateResponse;

                let company = CompanyFactory.fromFragment(response.data);
                props.cacheUpdater(company)
                navigate('/dashboard/companies/edit/' + company.id + '?cm=');
            } else {
                setError(
                    getTranslation('VIEW.COMPANY.ADD.ERROR')
                )
            }
            setIsLoading(false);
        })
    }

    return (
        <>
            <h2>
                {
                    getTranslation('VIEW.COMPANY.ADD.HEADLINE')
                }
            </h2>
            {
                isLoading &&
                <PlaceHolder className={'h-32'} />
            }
            {
                error &&
                <div role={'alert'} className={'p-6 text-br-red border border-br-red bg-red-50 my-3'}>
                    {error}
                </div>
            }
            <DashboardCompanyForm
                me={props.me}
                company={new Company()}
                onSubmit={onSubmit}
                accounts={props.accounts}
                cacheUsers={props.cacheUsers}
                services={props.services}
                update={false}
                disabled={isLoading}
            />
        </>
    )
}