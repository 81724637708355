import {BlueButton, GrayButton} from "@boomrank/react-components";
import {Link} from "react-router-dom";
import {useState} from "react";
import {getTranslation} from "../../intl";

interface Props {
    onSubmit: (login: string, password: string) => void
    disabled?: boolean
}

export function AuthLoginForm(props: Props) {
    let [login, setLogin] = useState('')
    let [errorLogin, setErrorLogin] = useState('')

    let [password, setPassword] = useState('')
    let [errorPassword, setErrorPassword] = useState('')

    let onSubmit = () => {
        setErrorLogin('')
        setErrorPassword('')
        let isValid = true

        if (login.length === 0) {
            isValid = false
            setErrorLogin(
                getTranslation('FORM.LOGIN.FILL_LOGIN_FIELD')
            )
        }
        if (password.length === 0) {
            isValid = false
            setErrorPassword(
                getTranslation('FORM.LOGIN.FILL_PASSWORD_FIELD')
            )
        }

        if (isValid) {
            props.onSubmit(login, password)
        }
    }

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'login') {
            setLogin(event.target.value)
        }
        if (event.target.name === 'password') {
            setPassword(event.target.value)
        }
    }

    let onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.currentTarget.name === 'password') {
            if (event.key.toLowerCase() === 'enter') {
                onSubmit();
            }
        }
    }

    return (
        <>
            <div className={'text-left px-6'}>
                <label className={'w-full block font-GothamBook leading-10 text-black'}>
                    {getTranslation('FORM.LOGIN.LABEL_LOGIN')}
                </label>
                <input
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    value={login}
                    name={'login'}
                    className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                    disabled={props.disabled || false}
                    data-testid={'login'}
                />
                {
                    errorLogin &&
                    <div role={'alert'} className={'text-br-red py-3'}>
                        {errorLogin}
                    </div>
                }
            </div>
            <div className={'text-left px-6'}>
                <label className={'w-full block font-GothamBook leading-10 text-black'}>
                    {getTranslation('FORM.LOGIN.LABEL_PASSWORD')}
                </label>
                <input
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    value={password}
                    name={'password'}
                    type={'password'}
                    className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                    disabled={props.disabled || false}
                    data-testid={'password'}
                />
                {
                    errorPassword &&
                    <div role={'alert'} className={'text-br-red py-3'}>
                        {errorPassword}
                    </div>
                }
            </div>
            <div className={'pt-6'}>
                {
                    !props.disabled ?
                        <BlueButton className={'mx-auto'} text={getTranslation('FORM.LOGIN.SUBMIT_LABEL')} onClick={() => {
                            onSubmit()
                        }}/>
                    :
                        <GrayButton className={'mx-auto'} text={getTranslation('FORM.LOGIN.SUBMIT_LABEL')}/>
                }
                <div className={'text-xs pt-6'}>
                    {
                        getTranslation('FORM.LOGIN.TERMS_LABEL').render(
                            <Link to={'/terms-of-services'} className={'underline text-br-blue'}>
                                {
                                    getTranslation('FORM.LOGIN.TERMS_OF_SERVICES')
                                }
                            </Link>
                        )
                    }
                </div>
            </div>

            <div className={'pt-6'}>
                <Link to={'/password/forgotten'} className={'block'}>
                    {
                        getTranslation('FORM.LOGIN.FORGOTTEN_PASSWORD')
                    }
                </Link>
            </div>
        </>
    )
}