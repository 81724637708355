import {AuthForgottenPasswordView} from "../views/forgotten-password";
import {getTranslation} from "../../intl";

interface Props {
}

export function AuthPasswordForgottenRoute(props: Props) {
    document.title = `${getTranslation('ROUTE.AUTH.PASSWORD_FORGOTTEN.TITLE')} | Boomrank.io`

    return (
        <AuthForgottenPasswordView />
    )
}