import logo from '@boomrank/web-assets/public/images/logo/boomrank_450x200.png';
import {AuthApi} from "../../services/auth/api";
import {useState} from "react";
import {PlaceHolder} from "@boomrank/react-components";
import {AuthPasswordForgottenForm} from "../forms/password-forgotten";
import {getTranslation} from "../../intl";


export function AuthForgottenPasswordView() {
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    let onSubmit = (email: string) => {
        setError('')
        setMessage('')
        setIsLoading(true)

        AuthApi.requestNewPassword(email)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    setMessage(
                        getTranslation('VIEW.FORGOTTEN_PASSWORD.SEND')
                    )
                    setIsSubmitted(true)
                } else {
                    setError(
                        getTranslation('VIEW.FORGOTTEN_PASSWORD.ERROR')
                    )
                }
                setIsLoading(false)
            })
    }

    return (
        <>
            <div className={'p-6'}>
                <img src={logo} alt={'digimood'} className={'mx-auto w-1/2'} />
                <span className={'text-br-blue text-base'}>
                    { getTranslation('VIEW.FORGOTTEN_PASSWORD.HEADLINE') }
                </span>
            </div>
            {
                isLoading &&
                <PlaceHolder className={'mx-8 h-6'} />
            }
            {
                error &&
                <div role={'alert'} className={'mx-8 text-br-red'}>
                    {error}
                </div>
            }
            {
                message &&
                <div role={'alert'} className={'mx-8 text-br-green'}>
                    {message}
                </div>
            }
            <AuthPasswordForgottenForm onSubmit={onSubmit} disabled={isLoading || isSubmitted} />
        </>
    )
}
