import React from "react";
import {Checkbox} from "@boomrank/react-components";
import {Company, User, Account} from "../../../models";
import {getTranslation} from "../../../intl";

interface Props {
    account: Account
    user: User
    company?: Company
    companies: Company[]
    onCompanyChange: (company: Company) => void
    onStatusChange: (isActive: boolean) => void
    onInputChange: (name: string, value: string) => void
    disabled?: boolean
}

export function DashboardAccountBasisForm(props: Props) {
    let onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.onCompanyChange(
            props.companies.filter(company => company.id.toString() === event.target.value)[0]
        )
    }

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onInputChange(event.target.name, event.target.value)
    }

    let onClick = (name: string, value: boolean) => {
        props.onStatusChange(value)
    }

    return (
        <>
            <div className={'text-left pb-6'}>
                <div className={'flex justify-center'}>
                    {
                        props.user.isActive &&
                        <Checkbox
                            key={props.user.id}
                            name={'is_active'}
                            label={
                                getTranslation('FORM.ACCOUNT.BASIS.LABEL_ACCOUNT_ACTIVE')
                            }
                            alias={
                                <i className="fa-solid fa-check" />
                            }
                            value={false}
                            onClick={onClick}
                            isChecked={props.user.isActive}
                            isDisabled={props.disabled || false}
                        />
                    }
                    {
                        !props.user.isActive &&
                        <Checkbox
                            key={props.user.id}
                            name={'is_active'}
                            label={
                                getTranslation('FORM.ACCOUNT.BASIS.LABEL_ACCOUNT_INACTIVE')
                            }
                            alias={
                                <i className="fa-solid fa-xmark" />
                            }
                            value={true}
                            onClick={onClick}
                            isChecked={props.user.isActive}
                            isDisabled={props.disabled || false}
                        />
                    }
                </div>
            </div>
            <div className={'text-left px-6'}>
                <label className={'w-full block font-GothamBook leading-10 text-black'}>
                    {
                        getTranslation('FORM.ACCOUNT.BASIS.LABEL_COMPANY')
                    }
                </label>
                {
                    !props.company &&
                    <select
                        value={props.account.company}
                        className={'w-full p-4 bg-white border-gray border text-gray font-GothamBook leading-6 h-14'}
                        name={'company'}
                        disabled={props.disabled || false}
                        onChange={onSelect}
                    >
                        <option disabled={true} key={0} value={0}>
                            {
                                getTranslation('FORM.ACCOUNT.BASIS.CHOOSE_COMPANY')
                            }
                        </option>
                        {
                            props.companies.map((company: Company) => {
                                return (
                                    <option key={company.id} value={company.id}>{company.name}</option>
                                )
                            })
                        }
                    </select>
                }
                {
                    props.company &&
                    <input value={props.company.name}
                           name={'company'}
                           type={'text'}
                           placeholder={''}
                           disabled={true}
                           className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                    />
                }
            </div>
            <div className={'lg:grid grid-cols-2 gap-3'}>
                <div className={'text-left px-6'}>
                    <label className={'w-full block font-GothamBook leading-10 text-black'}>
                        {
                            getTranslation('FORM.ACCOUNT.BASIS.LABEL_FIRSTNAME')
                        }
                    </label>
                    <input value={props.user.firstname}
                           onChange={onChange}
                           name={'firstname'}
                           type={'text'}
                           placeholder={''}
                           disabled={props.disabled || false}
                           className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                    />
                </div>
                <div className={'text-left px-6'}>
                    <label className={'w-full block font-GothamBook leading-10 text-black'}>
                        {
                            getTranslation('FORM.ACCOUNT.BASIS.LABEL_LASTNAME')
                        }
                    </label>
                    <input value={props.user.lastname}
                           onChange={onChange}
                           name={'lastname'}
                           type={'text'}
                           placeholder={''}
                           disabled={props.disabled || false}
                           className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                    />
                </div>
            </div>
            <div className={'text-left px-6'}>
                <label className={'w-full block font-GothamBook leading-10 text-black'}>
                    {
                        getTranslation('FORM.ACCOUNT.BASIS.LABEL_EMAIL')
                    }
                </label>
                <input value={props.user.email}
                       onChange={onChange}
                       name={'email'}
                       type={'email'}
                       placeholder={''}
                       disabled={props.disabled || false}
                       className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                />
            </div>
            <div className={'text-left px-6'}>
                <label className={'w-full block font-GothamBook leading-10 text-black'}>
                    {
                        getTranslation('FORM.ACCOUNT.BASIS.LABEL_JOB')
                    }
                </label>
                <input value={props.account.position}
                       onChange={onChange}
                       name={'position'}
                       type={'text'}
                       placeholder={''}
                       disabled={props.disabled || false}
                       className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                />
            </div>
        </>
    )
}