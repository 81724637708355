import {CompanyFragment} from "./fragments";
import {Company} from "../../models";

export class CompanyFactory {
    static fromFragment = (fragment: CompanyFragment): Company =>  {
        let company = new Company()
        company.id = fragment.id
        company.name = fragment.name
        company.isAdmin = fragment.is_admin
        company.logo = fragment.logo
        company.employees = fragment.employees
        company.experts = fragment.experts
        company.services = fragment.services
        return company
    }
}