import {CardCheckbox} from "@boomrank/react-components";
import {Company, Account} from "../../../models";
import {getTranslation} from "../../../intl";

interface Props {
    account: Account,
    companies: Company[],
    onCustomerClick: (company: Company) => void
    disabled?: boolean
}

export function DashboardAccountCustomersForm(props: Props) {
    let onClick = (name: string, company: Company) => {
        props.onCustomerClick(company)
    }

    return (
        <>
            <label className={'w-full block font-GothamBook leading-10 text-black'}>
                {
                    getTranslation('FORM.ACCOUNT.CUSTOMERS.LABEL_CUSTOMERS')
                }
            </label>
            <div className={'lg:grid grid-cols-3 gap-6'}>
                {
                    props.companies.filter(company => !company.isAdmin).map((company: Company) => {
                        return (
                            <CardCheckbox
                                key={company.id}
                                name={'customers[]'}
                                label={company.name}
                                headline={company.name}
                                alias={company.name.slice(0, 2).toUpperCase() }
                                value={company}
                                onClick={onClick}
                                isDisabled={
                                    props.account.company === company.id || (props.disabled || false)
                                }
                                isChecked={props.account.customers.indexOf(company.id) !== -1}
                            />
                        )
                    })
                }
            </div>
        </>
    )
}