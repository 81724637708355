import React, {useEffect, useState} from "react";
import {Account, Company, Me, Service, User} from "../../../models";
import {DashboardCompanyUploadForm} from "./upload";
import {DashboardCompanyBasisForm} from "./basis";
import {DashboardCompanyEmployeesForm} from "./employees";
import {DashboardCompanyExpertsForm} from "./experts";
import {DashboardCompanyServicesForm} from "./services";
import {GreenButton, GrayButton, Cache} from "@boomrank/react-components";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    company: Company
    cacheUsers: Cache<User>
    services: Service[]
    accounts: Account[]
    update: boolean
    onSubmit: (company: Company) => void
    onFileUpload?: (company: Company, files: FileList) => void
    onDelete?: (company: Company) => void
    onClickEmployee?: (account: Account) => void
    onClickNewEmployee?: (company: Company) => void
    onClickExpert?: (account: Account) => void
    disabled?: boolean
}

export function DashboardCompanyForm(props: Props) {
    let [company, setCompany] = useState<Company>(props.company);

    useEffect(() => {
        setCompany(props.company)
    }, [props.company])

    let onChecked = (name: string, service: Service) => {
        let services = [...company.services]

        let index = services.indexOf(service.id)
        if (index === -1) {
            services.push(service.id)
        } else {
            services.splice(index, 1)
        }
        setCompany({
            ...company,
            services: services
        })
    }

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompany({
            ...company,
            [event.target.name]: event.target.value
        })
    }

    let onDelete = () => {
        if (props.onDelete) {
            props.onDelete(company)
        }
    }

    let onFileChange = (files: FileList) => {
        if (props.onFileUpload) {
            props.onFileUpload(props.company, files)
        }
    }

    let onSubmit = () => {
        props.onSubmit(company)
    }

    let onClickEmployee = (account: Account) => {
        if (props.onClickEmployee) {
            props.onClickEmployee(account)
        }
    }

    let onClickNewEmployee = (company: Company) => {
        if (props.onClickNewEmployee) {
            props.onClickNewEmployee(company)
        }
    }

    let onClickExpert = (account: Account) => {
        if (props.onClickExpert) {
            props.onClickExpert(account)
        }
    }

    let experts = props.accounts.filter((account) => account.customers.indexOf(company.id) !== -1)
    let employees = props.accounts.filter((account) => account.company === company.id)
    return (
        <>
            <div className={'flex flex-row-reverse'}>
                {
                    (
                        props.me.user.isAdmin &&
                        props.me.company.id !== props.company.id &&
                        props.update
                    ) &&
                    <div className={'mb-3'}>
                        {
                            props.disabled ?
                                <GrayButton
                                    text={
                                        <i className={'fa-solid fa-trash-can'}/>
                                    }
                                />
                                :
                                <GrayButton
                                    text={
                                        <i className={'fa-solid fa-trash-can'}/>
                                    }
                                    onClick={() => {
                                        onDelete()
                                    }}
                                />
                        }
                    </div>
                }
            </div>
            <div className={'lg:grid grid-cols-2 gap-3'}>
                <div>
                    <DashboardCompanyBasisForm
                        company={company}
                        onChange={onChange}
                        disabled={props.disabled}
                    />
                    {
                        props.update &&
                        props.me.user.isAdmin &&
                        <div className={'mt-6'}>
                            <h3>{getTranslation('FORM.COMPANY.LABEL_SERVICES')}</h3>
                            <DashboardCompanyServicesForm
                                company={company}
                                onChecked={onChecked}
                                services={props.services}
                                disabled={props.disabled}
                            />
                        </div>
                    }
                </div>
                <div>
                    {
                        props.update &&
                        <DashboardCompanyUploadForm
                            company={company}
                            onFileChange={onFileChange}
                            disabled={props.disabled}
                        />
                    }
                </div>
            </div>
            <div className={'pt-6'}>
                {
                    props.disabled ?
                        <GrayButton text={
                            getTranslation('FORM.COMPANY.LABEL_SUBMIT')
                        } className={'mx-auto'} />
                    :
                        <GreenButton text={
                            getTranslation('FORM.COMPANY.LABEL_SUBMIT')
                        } onClick={onSubmit} className={'mx-auto'} />
                }

            </div>
            {
                props.update &&
                <>
                    <div className={'mt-6'}>
                        <h3>{getTranslation('FORM.COMPANY.LABEL_EMPLOYEES').format(employees.length)}</h3>
                        <DashboardCompanyEmployeesForm
                            company={company}
                            cacheUsers={props.cacheUsers}
                            employees={employees}
                            onClickNewEmployee={onClickNewEmployee}
                            onClick={onClickEmployee}
                            disabled={props.disabled}
                        />
                    </div>
                    <div className={'mt-6'}>
                        <h3>{getTranslation('FORM.COMPANY.LABEL_EXPERTS').format(experts.length)}</h3>
                        <DashboardCompanyExpertsForm
                            company={company}
                            cacheUsers={props.cacheUsers}
                            experts={experts}
                            onClick={onClickExpert}
                            disabled={props.disabled}
                        />
                    </div>
                </>
            }
        </>
    )
}