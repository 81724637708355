import {UserCreateResponse, UserListResponse, UserUpdateResponse} from "./responses";
import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {User} from "../../models";
import {endpoints} from "../api";

export class UserApi {
    static list(token: JwtToken, baseUrl?: string): Promise<FormErrorResponse | UserListResponse> {
        let url = endpoints.user.list;
        if (baseUrl) {
            url = baseUrl;
        }
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static create(token: JwtToken, user: User): Promise<FormErrorResponse | UserCreateResponse> {
        let url = endpoints.user.create;
        let data: {
            [field: string]: any
        } = {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
        }

        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify(data)
            }
        );
    }

    static update(
        token: JwtToken,
        user: User
    ): Promise<FormErrorResponse | UserUpdateResponse> {
        let url = endpoints.user.update.replace(':pk', user.id.toString());

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    'email': user.email,
                    'firstname': user.firstname,
                    'lastname': user.lastname,
                    'is_active': user.isActive
                })
            }
        );
    }

    static delete(token: JwtToken, user: User): Promise<any> {
        let url = endpoints.user.delete.replace(':pk', user.id.toString());
        return Api.call(
            url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }
}