import React from "react";
import {Company, User, Account} from "../../../models";
import {Card, Cache} from "@boomrank/react-components";

interface Props {
    company: Company
    experts: Account[]
    cacheUsers: Cache<User>
    onClick: (account: Account) => void
    disabled?: boolean
}

export function DashboardCompanyExpertsForm(props: Props) {
    let onClick = (account: Account) => {
        props.onClick(account)
    }

    return (
        <div className={'lg:grid grid-cols-5 gap-6 mt-4'}>
            {
                props.experts.map((account, idx: number) => {
                    let users = props.cacheUsers.get([account.user])
                    if (users.length > 0) {
                        let user = users[0]
                        return (
                            <Card
                                header={
                                    <div
                                        role={'button'}
                                        onClick={() => onClick(account)}
                                        className={'grow h-full flex items-center justify-center cursor-pointer'}
                                    >
                                        <i className={'text-white fa-solid fa-eye'}/>
                                    </div>
                                }
                                key={idx}
                                user={user}
                            />
                        )
                    }
                    return <React.Fragment key={idx} />
                })
            }
        </div>
    )
}