import {Company, Me} from "../../../models";
import {BlueButton, Breadcrumb, Cache} from "@boomrank/react-components";
import {useLocation, useNavigate} from "react-router-dom";
import React from "react";
import {DashboardCompanyView} from "../../views/company";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    cacheCompanies: Cache<Company>
}

export function DashboardCompanyRoute(props: Props) {
    let location = useLocation()
    let navigate = useNavigate()
    document.title = `${getTranslation('ROUTE.COMPANY.TITLE')} | Boomrank.io`

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content:<i className={'fa-solid fa-home'}/>, title: getTranslation('BREADCRUMB.HOMEPAGE')},
                    {to: '/dashboard/companies/', content: getTranslation('BREADCRUMB.COMPANIES')},
                ]}
            />
            <div className={'mt-3'}>
                <div className={'flex flex-row-reverse'}>
                    {
                        props.me.company.isAdmin &&
                        <BlueButton onClick={() => {
                            navigate('/dashboard/companies/add/')
                        }} text={
                            getTranslation('ROUTE.COMPANY.NEW_COMPANY')
                        } />
                    }
                </div>
                <DashboardCompanyView
                    companies={props.cacheCompanies.values()}
                />
            </div>
        </>
    )
}