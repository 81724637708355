import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {OAuth2Api} from "../../services/oauth2/api";
import {AuthorizationResponseSuccess} from "../../services/oauth2/responses";
import {JwtToken, PlaceHolder, SecureHTML} from "@boomrank/react-components";
import {Service} from "../../models";
import {ServiceFactory} from "../../services/service/factory";
import {AuthAuthorizeForm} from "../forms/authorize";
import {getTranslation} from "../../intl";

interface Props {
    token: JwtToken
}
// authorize/?client_id=fth7MQBZvo2khc4uXA6QHitCRGxHvVHPvDk7yxD3&scopes=plans%20profile&response_type=code&redirect_uri=https://kc.boomrank.io/callback/
export function AuthAuthorizeView(props: Props) {
    let location = useLocation()
    let navigate = useNavigate()

    let [isLoading, setIsLoading] = useState(true)
    let [allowed, setAllowed] = useState<boolean | undefined>()
    let [error, setError] = useState(false)


    let [scopes, setScopes] = useState<string[]>([])
    let [service, setService] = useState<Service | undefined>(undefined)
    let [redirect, setRedirect] = useState<string | undefined>(undefined)
    let [redirecting, setRedirecting] = useState<boolean>(false)

    let nbRetries = 0

    useEffect(() => {
        authorizationUrl()
    }, [props.token]);

    let authorizationUrl = () => {
        setRedirecting(false)
        setAllowed(undefined)
        setError(false)

        OAuth2Api.getAuthorize(
            props.token,
            location.search
        )
        .then(response => {
            setIsLoading(false)
            if (response.statusCode >= 200 && response.statusCode < 300) {
                setAllowed(true)
                let r = response as AuthorizationResponseSuccess
                if (r.data.previously_authorized) {
                    window.location.href = r.data.location
                    setRedirecting(true)
                }
                let service = ServiceFactory.fromFragment(r.data.service)
                setService(service)
                setScopes(r.data.scopes)
                setRedirect(r.data.location)
            }
            if (response.statusCode === 403) {
                setAllowed(false)
            }
            if (response.statusCode === 401) {
                if (nbRetries < 3) {
                    setIsLoading(true)
                    nbRetries += 1
                    setTimeout(() => {
                        authorizationUrl()
                    }, 2000)
                } else {
                    setError(true)
                }
            }
            if (response.statusCode >= 500) {
                setError(true)
            }
        })
    }

    let onSubmit = () => {
        if (redirect) {
            setRedirecting(true)
            window.location.href = redirect
        }
    }

    let onCancel = () => {
        navigate("/dashboard/")
    }

    if (isLoading) {
        return (
            <PlaceHolder className={'h-32'} />
        )
    }

    if (allowed === false) {
        return (
            <div role={"alert"} className={'text-xl text-br-red'}>
                { getTranslation('VIEW.AUTHORIZATION.ACCESS_DENIED') }
            </div>
        )
    }

    if (redirecting && service) {
        return (
            <div role={"alert"} className={'text-xl text-br-green'}>
                <SecureHTML>
                    { getTranslation('VIEW.AUTHORIZATION.REDIRECTING').format(service.name) }
                </SecureHTML>
            </div>
        )
    }

    if (allowed === true && !error && service) {
        return (
            <div className={'p-6'}>
                <AuthAuthorizeForm
                    disabled={isLoading}
                    service={service}
                    scopes={scopes}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            </div>
        )
    }

    return (
        <div role={"alert"} className={'text-xl text-br-red'}>
            { getTranslation('VIEW.AUTHORIZATION.ERROR') }
        </div>
    )
}
