import {Account, Company, Me, User} from "../../../models";
import {Breadcrumb, Cache, BlueButton} from "@boomrank/react-components";
import {useLocation, useNavigate} from "react-router-dom";
import React from "react";
import {DashboardAccountView} from "../../views/account";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    accounts: Account[]
    cacheUsers: Cache<User>
    cacheCompanies: Cache<Company>
}

export function DashboardAccountRoute(props: Props) {
    let location = useLocation()
    let navigate = useNavigate()
    document.title = `${getTranslation('ROUTE.ACCOUNT.TITLE')} | Boomrank.io`

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content:<i className={'fa-solid fa-home'}/>, title: getTranslation('BREADCRUMB.ACCOUNTS')},
                    {to: '/dashboard/accounts/', content: getTranslation('BREADCRUMB.ACCOUNTS')},
                ]}
            />
            <div className={'mt-3'}>
                <div className={'flex flex-row-reverse'}>
                    {
                        props.me.company.isAdmin &&
                            <BlueButton
                                onClick={() => {
                                    navigate('/dashboard/accounts/add/')
                                }}
                                text={getTranslation('ROUTE.ACCOUNT.NEW_ACCOUNT')}
                            />
                    }
                </div>
                <DashboardAccountView
                    cacheUsers={props.cacheUsers}
                    cacheCompanies={props.cacheCompanies}
                    accounts={props.accounts}
                />
            </div>
        </>
    )
}