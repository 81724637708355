import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import {Account, Company, User} from "../../../models";
import {Cache} from "@boomrank/react-components";
import {PaginateAdvancedTable} from "../../dummies/table";
import {getTranslation} from "../../../intl";

interface Props {
    cacheUsers: Cache<User>
    cacheCompanies: Cache<Company>
    accounts: Account[]
}

export function DashboardAccountView(props: Props) {
    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                columns: [
                    {
                        Header: getTranslation('VIEW.ACCOUNT.EMAIL'),
                        accessor: 'user.email',
                    },
                    {
                        Header: getTranslation('VIEW.ACCOUNT.FIRSTNAME'),
                        accessor: 'user.firstname',
                    },
                    {
                        Header: getTranslation('VIEW.ACCOUNT.LASTNAME'),
                        accessor: 'user.lastname',
                    },
                    {
                        Header: getTranslation('VIEW.ACCOUNT.POSITION'),
                        accessor: 'position',
                    },
                    {
                        Header: getTranslation('VIEW.ACCOUNT.COMPANY'),
                        accessor: 'company.name',
                    },
                    {
                        Header: getTranslation('VIEW.ACCOUNT.IS_ACTIVE'),
                        accessor: 'user.isActive',
                        Cell: ({row}: any) => {
                            if (row.original.user.isActive) {
                                return (
                                    <i className="fa-solid fa-check text-br-blue" title={
                                        getTranslation('VIEW.ACCOUNT.ACCOUNT_ACTIVE')
                                    }/>
                                )
                            }
                            return <i className="fa-solid fa-xmark text-red-500" title={
                                getTranslation('VIEW.ACCOUNT.ACCOUNT_INACTIVE')
                            }/>
                        },
                    },
                    {
                        Header: '',
                        accessor: 'actions',
                        Cell: ({row}: any) => {
                            return (
                                <Link className={'btn btn-blue'} to={'/dashboard/accounts/edit/' + row.original.id}>
                                    <i className="fa-solid fa-address-card"/>
                                </Link>
                            )
                        },
                    }
                ],
            }
        ],
        []
    )

    let formatRows = (accounts: Account[]): any => {
        let rows: any[] = [];
        accounts.forEach((account) => {
            let users = props.cacheUsers.get([account.user])
            let companies = props.cacheCompanies.get([account.company])
            if (users.length > 0 && companies.length > 0) {
                let user = users[0]
                let company = companies[0]
                let row: any = {
                    ...account,
                    company: company,
                    user: {
                        ...user
                    }
                }
                rows.push(row)
            }
        })
        return rows;
    }
    const data = useMemo(() => formatRows(props.accounts), [props.accounts])

    return (
        <PaginateAdvancedTable
            columns={columns}
            data={data}
            renderRowSubComponent={null}
            initialState={{
                pageSize: 100
            }}
        />
    )
}