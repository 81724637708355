import {useEffect, useState, Dispatch, SetStateAction} from "react";
import {Account, Company, Me, Service, User} from "../models";
import {CompanyApi} from "../services/company/api";
import {CompanyListResponse} from "../services/company/responses";
import { Route, Routes} from "react-router-dom";
import {
    PlaceHolder,
    Cache, JwtToken,
} from "@boomrank/react-components";
import {AuthApi} from "../services/auth/api";
import {MeSuccess} from "../services/auth/responses";
import {AccountFactory} from "../services/account/factory";
import {CompanyFactory} from "../services/company/factory";
import {UserApi} from "../services/user/api";
import {UserListResponse} from "../services/user/responses";
import {UserFactory} from "../services/user/factory";
import {AccountApi} from "../services/account/api";
import {AccountListResponse} from "../services/account/responses";
import {ServiceApi} from "../services/service/api";
import {ServiceListResponse} from "../services/service/responses";
import {ServiceFactory} from "../services/service/factory";
import {MeFactory} from "../services/auth/factory";
import {CompanyFragment} from "../services/company/fragments";
import {Identifier} from "../models";
import {UserFragment} from "../services/user/fragments";
import {AccountFragment} from "../services/account/fragments";
import {ServiceFragment} from "../services/service/fragments";
import {Template} from "./dummies/template";
import {DashboardProfileChangePasswordRoute} from "./routes/profile/change-password";
import {DashboardProfileRoute} from "./routes/profile";
import {DashboardHomeRoute} from "./routes/home";
import {DashboardCompanyAddRoute} from "./routes/company/add";
import {DashboardCompanyEditRoute} from "./routes/company/edit";
import {DashboardCompanyRoute} from "./routes/company";
import {DashboardAccountAddRoute} from "./routes/account/add";
import {DashboardAccountEditRoute} from "./routes/account/edit";
import {DashboardAccountRoute} from "./routes/account";

interface Props {
    token: JwtToken
}

export function Dashboard(props: Props) {
    let [me, setMe] = useState<Me | null>(null)

    let [cacheCompanies, setCacheCompanies] = useState<Cache<Company>>(new Cache<Company>('id'))
    let [cacheUsers, setCacheUsers] = useState<Cache<User>>(new Cache<User>('id'))
    let [cacheAccounts, setCacheAccounts] = useState<Cache<Account>>(new Cache<Account>('id'))
    let [cacheServices, setCacheServices] = useState<Cache<Service>>(new Cache<Service>('id'))

    let [companiesLoaded, setCompaniesLoaded] = useState(false)
    let [usersLoaded, setUsersLoaded] = useState(false)
    let [accountsLoaded, setAccountsLoaded] = useState(false)
    let [servicesLoaded, setServicesLoaded] = useState(false)

    let cacheUpdater = (obj: Identifier, remove = false) => {
        let cache: Cache<any> | undefined
        let setCache: Dispatch<SetStateAction<Cache<any>>> | undefined

        if (obj instanceof Company) {
            cache = cacheCompanies
            setCache = setCacheCompanies
        }

        if (obj instanceof User) {
            cache = cacheUsers
            setCache = setCacheUsers
        }

        if (obj instanceof Account) {
            cache = cacheAccounts
            setCache = setCacheAccounts
        }

        if (obj instanceof Service) {
            cache = cacheServices
            setCache = setCacheServices
        }

        if (cache !== undefined && setCache !== undefined) {
            cache.add(obj)
            if (remove) {
                cache.delete(obj)
            }
            setCache({
                ...cache
            })
        }
    }

    let getCompanies = (url?: string) => {
        CompanyApi.list(props.token, url)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as CompanyListResponse
                    let companies = response.data.results.map((fragment: CompanyFragment) => {
                        return CompanyFactory.fromFragment(fragment)
                    })
                    let cache = new Cache<Company>('id').add(companies)
                    setCacheCompanies(cacheCompanies => cacheCompanies.merge(cache))

                    if (response.data.next) {
                        return getCompanies(response.data.next)
                    }
                }
                setCompaniesLoaded(true)
            })
    }

    let getUsers = (url?: string) => {
        UserApi.list(props.token, url)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as UserListResponse
                    let users = response.data.results.map((fragment: UserFragment) => {
                        return UserFactory.fromFragment(fragment)
                    })
                    let cache = new Cache<User>('id').add(users)
                    setCacheUsers(cacheUsers => cacheUsers.merge(cache))

                    if (response.data.next) {
                        return getUsers(response.data.next)
                    }
                }
                setUsersLoaded(true)
            })
    }

    let getAccounts = (url?: string) => {
        AccountApi.list(props.token, url)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as AccountListResponse
                    let accounts = response.data.results.map((fragment: AccountFragment) => {
                        return AccountFactory.fromFragment(fragment)
                    })
                    let cache = new Cache<Account>('id').add(accounts)
                    setCacheAccounts(cacheAccounts => cacheAccounts.merge(cache))

                    if (response.data.next) {
                        return getAccounts(response.data.next)
                    }
                }
                setAccountsLoaded(true)
            })
    }

    let getServices = (url?: string) => {
        ServiceApi.list(props.token, url)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as ServiceListResponse
                    let services = response.data.results.map((fragment: ServiceFragment) => {
                        return ServiceFactory.fromFragment(fragment)
                    })
                    let cache = new Cache<Service>('id').add(services)
                    setCacheServices(cacheServices => cacheServices.merge(cache))

                    if (response.data.next) {
                        return getServices(response.data.next)
                    }
                }
                setServicesLoaded(true)
            })
    }

    let getMe = () => {
        AuthApi.getMe(props.token)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as MeSuccess
                    let me = MeFactory.fromFragment(response.data)
                    setMe(me)
                }
            })
    }

    useEffect(() => {
        getMe()
        getCompanies()
        getUsers()
        getAccounts()
        getServices()
    }, [props.token])

    if (!me) {
        return (
            <PlaceHolder className={'h-32'} />
        )
    }

    if (
        companiesLoaded &&
        usersLoaded &&
        accountsLoaded &&
        servicesLoaded
    ) {
        return (
            <Template
                me={me}
            >
                <Routes>
                    <Route index element={
                        <DashboardHomeRoute
                            me={me}
                            cacheUsers={cacheUsers}
                            cacheAccounts={cacheAccounts}
                            cacheServices={cacheServices}
                            cacheCompanies={cacheCompanies}
                        />
                    }/>
                    <Route path={'/accounts/'} element={
                        <DashboardAccountRoute
                            me={me}
                            accounts={cacheAccounts.values()}
                            cacheUsers={cacheUsers}
                            cacheCompanies={cacheCompanies}
                        />
                    }/>
                    <Route path={'/accounts/edit/:accountId/'} element={
                        <DashboardAccountEditRoute
                            me={me}
                            token={props.token}
                            cacheCompanies={cacheCompanies}
                            cacheUsers={cacheUsers}
                            cacheAccounts={cacheAccounts}
                            cacheUpdater={cacheUpdater}
                        />
                    }/>
                    <Route path={'accounts/add/'} element={
                        <DashboardAccountAddRoute
                            me={me}
                            token={props.token}
                            cacheCompanies={cacheCompanies}
                            cacheUpdater={cacheUpdater}
                        />
                    }/>
                    <Route path={'/companies/'} element={
                        <DashboardCompanyRoute
                            me={me}
                            cacheCompanies={cacheCompanies}
                        />
                    }/>
                    <Route path={'/companies/edit/:companyId/'} element={
                        <DashboardCompanyEditRoute
                            me={me}
                            token={props.token}
                            accounts={cacheAccounts.values()}
                            services={cacheServices.values()}
                            cacheUsers={cacheUsers}
                            cacheCompanies={cacheCompanies}
                            cacheUpdater={cacheUpdater}
                        />
                    }/>
                    <Route path={'/companies/add/'} element={
                        <DashboardCompanyAddRoute
                            me={me}
                            token={props.token}
                            accounts={cacheAccounts.values()}
                            cacheUsers={cacheUsers}
                            services={cacheServices.values()}
                            cacheUpdater={cacheUpdater}
                        />
                    }/>

                    <Route path={'/profile/'} element={
                        <DashboardProfileRoute
                            token={props.token}
                            me={me}
                            cacheUpdater={cacheUpdater}
                        />
                    }/>
                    <Route path={'/profile/password/'} element={
                        <DashboardProfileChangePasswordRoute
                            token={props.token}
                        />
                    }/>
                </Routes>
            </Template>
        )
    }
    return (
        <PlaceHolder className={'h-32'} />
    )
}
