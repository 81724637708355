import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import {Company} from "../../../models";
import {PaginateAdvancedTable} from "../../dummies/table";
import {getTranslation} from "../../../intl";

interface Props {
    companies: Company[]
}

export function DashboardCompanyView(props: Props) {
    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                columns: [
                    {
                        Header: getTranslation('VIEW.COMPANY.NAME'),
                        accessor: 'name',
                    },
                    {
                        Header: getTranslation('VIEW.COMPANY.NB_EMPLOYEES'),
                        accessor: 'nbEmployees',
                    },
                    {
                        Header: '',
                        accessor: 'actions',
                        Cell: ({row}: any) => {
                            return (
                                <div className={'text-right flex flex-row-reverse'}>
                                    <Link className={'btn btn-blue'}
                                          to={'/dashboard/companies/edit/' + row.original.id}>
                                        <i className="fa-solid fa-address-card"/>
                                    </Link>
                                </div>
                            )
                        },
                    }
                ],
            }
        ],
        []
    )

    let formatRows = (companies: Company[]): any => {
        let rows: any[] = [];
        companies.forEach((company) => {
            rows.push({
                ...company,
                nbEmployees: company.employees.length
            })
        })
        return rows;
    }

    const data = useMemo(() => formatRows(props.companies), [props.companies])
    return (
        <PaginateAdvancedTable
            columns={columns}
            data={data}
            renderRowSubComponent={null}
            initialState={{
                pageSize: 100
            }}
        />
    )
}