import {endpoints} from "../api";
import {MeSuccess} from "./responses";
import {Api, AuthApi as BoomrankAuthApi, JwtToken, TokenError} from "@boomrank/react-components";
import {AccessTokenSuccess} from "@boomrank/react-components/src/services/auth/responses";


export class AuthApi {
    static getMe (token: JwtToken) : Promise<MeSuccess | TokenError>  {
        return BoomrankAuthApi.getMe(token)
    }

    static getAccessToken(username: string, password: string) : Promise<AccessTokenSuccess | TokenError> {
        let url = endpoints.auth.access_token;
        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            }
        );
    }

    static requestNewPassword (username: string) : Promise<any>  {
        let url = endpoints.auth.password_reset;
        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'username': username
                })
            }
        )
    }

    static changePassword (token: JwtToken, password: string) : Promise<any>  {
        let url = endpoints.auth.password_change;
        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    'password': password
                })
            }
        )
    }
}