import {Company, Service} from "../../../models";
import React from "react";
import {Checkbox} from "@boomrank/react-components";

interface Props {
    company: Company
    onChecked?: (name: string, value: Service) => void
    services: Service[]
    disabled?: boolean
}

export function DashboardCompanyServicesForm(props: Props) {
    let onClick = (name: string, value: Service) => {
        if(props.onChecked) {
            props.onChecked(name, value)
        }
    }

    return (
        <div className={'lg:grid grid-cols-3 gap-6 mt-4'}>
            {
                props.services.map((service, idx: number) => {
                    return (
                        <Checkbox
                            key={idx}
                            name={'services[]'}
                            label={service.name}
                            alias={service.name.slice(0, 2).toUpperCase()}
                            value={service}
                            onClick={onClick}
                            isChecked={props.company.services.indexOf(service.id) !== -1}
                            isDisabled={props.disabled || false}
                        />
                    )
                })
            }
        </div>
    )
}