import {BlueButton, GrayButton} from "@boomrank/react-components";
import React, {useEffect, useState} from "react";
import {Me} from "../../../models";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    onSubmit: (me: Me) => void
    disabled?: boolean
}

export function DashboardProfileEditForm(props: Props) {
    let [me, setMe] = useState<Me>(props.me)
    let [error, setError] = useState('')

    useEffect(() => {
        setMe(props.me)
    }, [props.me])

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (['email', 'firstname', 'lastname'].indexOf(event.target.name) !== -1) {
            setMe({
                ...me,
                user: {
                    ...me.user,
                    [event.target.name]: event.target.value
                }
            })
        }
        else {
            setMe({
                ...me,
                [event.target.name]: event.target.value
            })
        }
    }

    let onSubmit = () => {
        let isValid = true
        setError('')

        if (isValid) {
            props.onSubmit(me)
        }
    }

    return (
        <div>
            {
                error &&
                <div role={'alert'} className={'p-6 text-br-red border border-br-red bg-red-50 my-3'}>
                    {error}
                </div>
            }
            <div className={'text-left px-6'}>
                <label className={'w-full block font-GothamBook leading-10 text-black'}>
                    {getTranslation('FORM.PROFILE.EDIT.LABEL_FIRSTNAME')}
                </label>
                <input value={me.user.firstname}
                       onChange={onChange}
                       name={'firstname'}
                       type={'text'}
                       className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                       disabled={props.disabled || false}
                />
            </div>
            <div className={'text-left px-6'}>
                <label className={'w-full block font-GothamBook leading-10 text-black'}>
                    {getTranslation('FORM.PROFILE.EDIT.LABEL_LASTNAME')}
                </label>
                <input value={me.user.lastname}
                       onChange={onChange}
                       name={'lastname'}
                       type={'text'}
                       className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                       disabled={props.disabled || false}
                />
            </div>
            <div className={'text-left px-6'}>
                <label className={'w-full block font-GothamBook leading-10 text-black'}>
                    {getTranslation('FORM.PROFILE.EDIT.LABEL_EMAIL')}
                </label>
                <input value={me.user.email}
                       onChange={onChange}
                       name={'email'}
                       type={'email'}
                       className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                       disabled={props.disabled || false}
                />
            </div>
            <div className={'text-left px-6'}>
                <label className={'w-full block font-GothamBook leading-10 text-black'}>
                    {getTranslation('FORM.PROFILE.EDIT.LABEL_POSITION')}
                </label>
                <input value={me.position}
                       onChange={onChange}
                       name={'position'}
                       type={'text'}
                       className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                       disabled={props.disabled || false}
                />
            </div>
            <div className={'pt-6 text-center'}>
                {
                    props.disabled ?
                        <GrayButton text={getTranslation('FORM.PROFILE.EDIT.LABEL_SUBMIT')} />
                        :
                        <BlueButton text={getTranslation('FORM.PROFILE.EDIT.LABEL_SUBMIT')} onClick={onSubmit} />
                }
            </div>
        </div>
    )
}