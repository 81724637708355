import React, {useState} from "react";
import {Account, Company, Me, Service, User} from "../../models";
import {AddNewCard, Card, Cache, SecureHTML} from "@boomrank/react-components";
import {CompanyCard} from "../../components/dummies/card";
import classNames from "classnames";
import {Link, useNavigate} from "react-router-dom";
import {getTranslation} from "../../intl";

interface Props {
    me: Me
    cacheServices: Cache<Service>
    cacheAccounts: Cache<Account>
    cacheUsers: Cache<User>
    cacheCompanies: Cache<Company>
}

export function DashboardHomeView(props: Props) {
    let navigate = useNavigate()
    let [visible, setVisible] = useState(false)

    let onClick = () => {
        setVisible(!visible)
    }

    let onNewCardClick = () => {
        navigate('/dashboard/accounts/add/?cid=' + props.me.company.id)
    }

    return (
        <>
            <h3>
                <SecureHTML>
                    {
                        getTranslation('VIEW.HOME.LABEL_SERVICES').format(
                            props.cacheServices.get(props.me.company.services).length
                        )
                    }
                </SecureHTML>
            </h3>
            <div className={'lg:grid grid-cols-5 gap-6 mt-4'}>
                {
                    props.me.company.services.map((id, idx) => {
                        let services = props.cacheServices.get([id])
                        if(services.length > 0) {
                            let service = services[0]
                            return (
                                <a href={service.url} title={service.name}
                                   className={'p-6 rounded flex no-underline mb-4 lg:mb-0 shadow-md ring-1 ring-gray-300 hover:ring-br-blue'}
                                   target={'_blank'}
                                   rel={'noreferrer'}
                                   key={idx}
                                   style={{
                                       backgroundColor: '#FFF'
                                   }}
                                >
                                    <div className={"w-16 h-16"}>
                                        <img src={service.logo} alt={service.name}/>
                                    </div>
                                    <div className={'ml-4'} style={{
                                        color: '#333'
                                    }}>
                                        <div className={'text-xl mb-4'}>{service.name}</div>
                                        <div>{service.description}</div>
                                    </div>
                                </a>
                            )
                        }
                        return (
                            <React.Fragment key={idx} />
                        )
                    })
                }
            </div>
            {
                (!props.me.company.isAdmin && props.me.company.experts.length > 0) &&
                <>
                    <h3 className={'mt-6'}>
                        <SecureHTML>
                        {
                            getTranslation('VIEW.HOME.LABEL_EXPERTS').format(
                                props.cacheAccounts.get(props.me.company.experts).length
                            )
                        }
                        </SecureHTML>
                    </h3>
                    <div className={'lg:grid grid-cols-5 gap-6 mt-4'}>
                        {
                            props.me.company.experts.map((id, idx: number) => {
                                let accounts = props.cacheAccounts.get([id])
                                if (accounts.length > 0) {
                                    let account = accounts[0]

                                    let users = props.cacheUsers.get([account.user])
                                    if (users.length > 0) {
                                        let user = users[0]
                                        return (
                                            <Card key={idx} user={user} headline={account.position}/>
                                        )
                                    }
                                }
                                return (
                                    <React.Fragment key={idx} />
                                )
                            })
                        }
                    </div>
                </>
            }
            {
                (props.me.company.isAdmin && props.me.customers.length > 0) &&
                <>
                    <h3 className={'mt-6'}>
                        <SecureHTML>
                            {
                                getTranslation('VIEW.HOME.LABEL_CUSTOMERS').format(
                                    props.cacheAccounts.get(props.me.customers).length
                                )
                            }
                        </SecureHTML>
                    </h3>
                    <div className={'lg:grid grid-cols-5 gap-6 mt-4'}>
                        {
                            props.me.customers.map((id, idx: number) => {
                                let companies = props.cacheCompanies.get([id])
                                if (companies.length > 0) {
                                    let company = companies[0]
                                    return (
                                        <CompanyCard
                                            key={idx}
                                            company={company}
                                            onClick={() => {
                                                navigate(`/dashboard/companies/edit/${company.id}/`)
                                            }}
                                        />
                                    )
                                }
                                return (
                                    <React.Fragment key={idx} />
                                )
                            })
                        }
                    </div>
                </>
            }

            <h3 className={'mt-6'}>
                <SecureHTML>
                {
                    getTranslation('VIEW.HOME.LABEL_EMPLOYEES').format(
                        props.cacheAccounts.get(props.me.company.employees).length,
                        props.me.company.name
                    )
                }
                </SecureHTML>
            </h3>
            <div className={'lg:grid grid-cols-5 gap-6 mt-4'}>
                <AddNewCard label={'Nouveau membre'} onClick={onNewCardClick}/>
                {
                    props.me.company.employees.map((id, idx: number) => {
                        let accounts = props.cacheAccounts.get([id])
                        if (accounts.length > 0) {
                            let account = accounts[0]

                            let users = props.cacheUsers.get([account.user])
                            if(users.length > 0) {
                                let user = users[0]

                                if (user.isActive) {
                                    let cls = classNames({
                                        ' hidden': (idx > 8 && !visible)
                                    })
                                    return (
                                        <Card
                                            header={
                                                <Link to={'/dashboard/accounts/edit/' + id}
                                                      className={'grow h-full flex items-center justify-center'}>
                                                    <i className={'text-white fa-solid fa-user'}/>
                                                </Link>
                                            }
                                            className={cls}
                                            key={idx}
                                            user={user}
                                            headline={account.position}
                                        />
                                    )
                                }
                            }
                        }
                        return (
                            <React.Fragment key={idx} />
                        )
                    })
                }
            </div>
            {
                (props.me.company.employees.length > 8 && !visible) &&
                <div className={'text-center p-6'}>
                    <div role={'button'} className={'btn btn-gray'} onClick={onClick}>
                        {getTranslation('VIEW.HOME.LABEL_MORE_DETAILS')}
                    </div>
                </div>
            }
        </>
    )
}
