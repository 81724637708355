import {JwtToken, PlaceHolder, Cache} from "@boomrank/react-components";
import {Account, Company, Identifier, Me, User} from "../../../models";
import React, {useState} from "react";
import {UserApi} from "../../../services/user/api";
import {UserCreateResponse} from "../../../services/user/responses";
import {UserFactory} from "../../../services/user/factory";
import {AccountApi} from "../../../services/account/api";
import {AccountCreateResponse} from "../../../services/account/responses";
import {AccountFactory} from "../../../services/account/factory";
import {useNavigate} from "react-router-dom";
import {DashboardAccountForm} from "../../forms/account";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    token: JwtToken
    company?: Company
    cacheCompanies: Cache<Company>
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
}

export function DashboardAccountAddView(props: Props) {
    let navigate = useNavigate()
    let [isLoading, setIsLoading] = useState(false)
    let [error, setError] = useState('')

    let onSubmit = (account: Account, user: User) => {
        setIsLoading(true)
        setError('')

        UserApi.create(props.token, user).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as UserCreateResponse

                let user = UserFactory.fromFragment(response.data)
                props.cacheUpdater(user)

                AccountApi.create(props.token, user, account).then((response) => {
                    if (response.statusCode >= 200 && response.statusCode < 300) {
                        response = response as AccountCreateResponse

                        let account = AccountFactory.fromFragment(response.data)
                        props.cacheUpdater(account)
                        navigate(`/dashboard/accounts/edit/${account.id}/`)
                    } else {
                        UserApi.delete(props.token, user)
                        props.cacheUpdater(user, true)
                        setError(
                            getTranslation('VIEW.ACCOUNT.ADD.ERROR')
                        )
                    }
                    setIsLoading(false)
                })
            } else {
                setIsLoading(false)
                setError(
                    getTranslation('VIEW.ACCOUNT.ADD.ERROR')
                )
            }
        });
    }

    let account = new Account()
    if (props.company) {
        account.company = props.company.id
    }

    return (
        <>
            <h2 className={'mb-6'}>
                {
                    getTranslation('VIEW.ACCOUNT.ADD.HEADLINE')
                }
            </h2>
            {
                isLoading &&
                <PlaceHolder className={'h-32'} />
            }
            {
                error &&
                <div role={'alert'} className={'p-6 text-br-red border border-br-red bg-red-50 my-3'}>
                    {error}
                </div>
            }
            <DashboardAccountForm
                me={props.me}
                account={account}
                user={new User()}
                update={false}
                onSubmit={onSubmit}
                company={props.company}
                companies={props.cacheCompanies.values()}
                disabled={isLoading}
            />
        </>
    )
}