import React, {useState} from "react";
import {DashboardCompanyForm} from "../../forms/company";
import {Company, Identifier, Me, Service, User, Account} from "../../../models";
import {CompanyApi} from "../../../services/company/api";
import {CompanyUpdateResponse, CompanyUploadResponse} from "../../../services/company/responses";
import {CompanyFactory} from "../../../services/company/factory";
import {JwtToken, Cache, PlaceHolder} from "@boomrank/react-components";
import {getTranslation} from "../../../intl";
import {useNavigate} from "react-router-dom";

interface Props {
    me: Me
    token: JwtToken
    company: Company
    accounts: Account[]
    services: Service[]
    cacheUsers: Cache<User>
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
}

export function DashboardCompanyEditView(props: Props) {
    let navigate = useNavigate()
    let [isLoading, setIsLoading] = useState(false)
    let [error, setError] = useState('')
    let [success, setSuccess] = useState('')

    let onSubmit = (company: Company) => {
        setIsLoading(true)
        setError('')
        setSuccess('')

        CompanyApi.update(props.token, company).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as CompanyUpdateResponse;
                let company = CompanyFactory.fromFragment(response.data);
                props.cacheUpdater(company)
                setSuccess(
                    getTranslation('VIEW.COMPANY.EDIT.SUCCESS')
                )
            } else {
                setError(
                    getTranslation('VIEW.COMPANY.EDIT.ERROR')
                )
            }
            setIsLoading(false);
        });
    }

    let onFileChange = (company: Company, files: FileList) => {
        setIsLoading(true)
        setError('')
        setSuccess('')

        CompanyApi.upload(props.token, company, files[0]).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as CompanyUploadResponse;

                let company = CompanyFactory.fromFragment(response.data);
                props.cacheUpdater(company)
                setSuccess(
                    getTranslation('VIEW.COMPANY.EDIT.UPLOAD_SUCCESS')
                )
            } else {
                setError(
                    getTranslation('VIEW.COMPANY.EDIT.ERROR')
                )
            }
            setIsLoading(false);
        });
    }

    let onDelete = (company: Company) => {
        let confirm = window.confirm(
            getTranslation('VIEW.COMPANY.EDIT.CONFIRM_DELETE').format(company.name)
        )
        if(confirm) {
            setIsLoading(true)
            CompanyApi.delete(props.token, company).then((response) => {
                setIsLoading(false)
                props.cacheUpdater(company, true)
                navigate('/dashboard/companies/')
            })
        }
    }

    let onClickNewEmployee = (company: Company) => {
        navigate(`/dashboard/accounts/add/?cid=${company.id}`)
    }

    let onClickEmployee = (account: Account) => {
        navigate(`/dashboard/accounts/edit/${account.id}`)
    }

    return (
        <>
            {
                isLoading &&
                <PlaceHolder className={'mx-8 h-6'} />
            }
            {
                error &&
                <div role={'alert'} className={'p-6 text-br-red border border-br-red bg-red-50 my-3'}>
                    {error}
                </div>
            }
            {
                success &&
                <div role={'alert'} className={'p-6 text-br-green border border-br-green bg-green-50 my-3'}>
                    {success}
                </div>
            }
            <DashboardCompanyForm
                me={props.me}
                company={props.company}
                onSubmit={onSubmit}
                onFileUpload={onFileChange}
                onDelete={onDelete}
                update={true}
                accounts={props.accounts}
                cacheUsers={props.cacheUsers}
                services={props.services}
                disabled={isLoading}
                onClickNewEmployee={onClickNewEmployee}
                onClickEmployee={onClickEmployee}
            />
        </>
    )
}