import {BrowserRouter, Routes, Route} from "react-router-dom";
import {useEffect, useState} from "react";
import {CookiesProvider} from "react-cookie";
import {Auth} from "./auth";
import {
    JwtToken,
    StorageJwtToken,
    PageDefaultView,
    PrivateRoute,
    Firewall,
    AuthApi,
    RefreshTokenSuccess, JwtTokenFactory
} from "@boomrank/react-components";
import TermsOfService from "./pages/terms-of-service";
import {Dashboard} from "./dashboard";


export function App() {
    let storage = new StorageJwtToken(process.env.REACT_APP_KEY_STORAGE!)
    const [token, setToken] = useState<JwtToken | null>(storage.get());

    let refreshToken = (token: JwtToken) => {
        AuthApi.getRefreshToken(token.refresh)
            .then(response => {
                let r = response as RefreshTokenSuccess;
                if (response.statusCode === 200) {
                    let newToken = JwtTokenFactory.fromFragment({
                        refresh: token.refresh,
                        ...r.data
                    })
                    token.expired_at = newToken.expired_at
                    token.access = newToken.access

                    storage.set(token)
                    setToken(token)
                } else {
                    storage.remove()
                    setToken(null)
                }
            })
    }

    let verifyToken = (token: JwtToken) => {
        AuthApi.getVerifyToken(token)
            .then(response => {
                if (response.statusCode === 401) {
                    refreshToken(token)
                }
            })
    }

    let onCheck = () => {
        if (token) {
            let today = new Date();
            if (today > token.expired_at) {
                refreshToken(token)
            }
        }
    }

    let onLogin = (token: JwtToken) => {
        setToken(token)
    }

    let onLogout = () => {
        setToken(null)
    }

    useEffect(() => {
        if (token) {
            verifyToken(token)
        }
    }, [token])

    return (
        <div className={process.env.REACT_APP_RELEASE_BRANCH}>
            <CookiesProvider>
                <BrowserRouter>
                    <Routes>
                        <Route index element={<PageDefaultView/>}/>
                        <Route path={'/terms-of-services'} element={<TermsOfService />}/>
                        <Route path={'*'} element={
                            <Auth token={token!} onCheck={onCheck} onLogin={onLogin} onLogout={onLogout} />
                        }/>
                        <Route path="/dashboard/*" element={
                            <PrivateRoute token={token!}>
                                <Firewall token={token!} onCheck={onCheck}>
                                    <Dashboard token={token!} />
                                </Firewall>
                            </PrivateRoute>
                        } />
                    </Routes>
                </BrowserRouter>
            </CookiesProvider>
        </div>
    )
}
