import React from "react";
import {Company} from "../../../models";
import {getTranslation} from "../../../intl";

interface Props {
    company: Company,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean
}

export function DashboardCompanyBasisForm(props: Props) {
    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event)
    }

    return (
        <>
            <label className={'w-full block font-GothamBook leading-10 text-black'}>
                {
                    getTranslation('FORM.COMPANY.BASIS.LABEL_NAME')
                }
            </label>
            <input defaultValue={props.company.name}
                   onChange={onChange}
                   name={'name'}
                   type={'text'}
                   disabled={props.disabled || false}
                   className={'w-full p-4 border-gray border text-gray font-GothamBook'}

            />
        </>
    )
}