import logo from '@boomrank/web-assets/public/images/logo/boomrank_450x200.png';
import {Link} from "react-router-dom";
import {MinimalTemplate} from "@boomrank/react-components";


function TermsOfService() {
    return (
        <MinimalTemplate>
            <div className={'flex flex-col h-screen'}>
                <div className={'p-6 py-24 flex-none'}>
                    <img src={logo} alt={'digimood'} className={'mx-auto w-1/2'} />
                    <span className={'text-br-blue'}>Conditions Générales</span>
                </div>
                <div className={'p-6 flex-shrink overflow-auto text-justify'}>
                    <h3>MENTIONS LÉGALES</h3>

                    <p>
                        Digimood est une&nbsp;<strong>agence de communication digitale</strong>&nbsp;et de
                        conseil en webmarketing, basée à Marseille. Le site est édité par la société par
                        actions simplifiée DIGIMOOD au capital de 50.000 €, inscrite au RCS de Marseille
                        sous le numéro d’immatriculation&nbsp;<strong>SIRET 501 194 500 00036</strong>. Le
                        directeur de publication du site www.digimood.com est Jean-Marc FORTES.
                    </p>

                    <p>
                        <strong>Digimood</strong><br/>32 Bis Avenue André Roussin<br/>13016 Marseille<br/>Tel :
                        04 91 65 62 63
                    </p>

                    <p>Le site www.digimood.com est hébergé par OVH.</p>

                    <h3>CONDITIONS GÉNÉRALES D’UTILISATION</h3>
                    <h4>Préambule</h4>

                    <p>
                        Les présentes conditions s’appliquent à l’intégralité du site&nbsp;
                        <strong>www.digimood.com</strong>&nbsp;mais également à l’ensemble des sous-domaines
                        et domaines appartenant à la société Digimood.
                    </p>

                    <h4>Propriété intellectuelle</h4>

                    <p>Digimood est une&nbsp;<strong>marque déposée à l’INPI au numéro 3999506</strong>.</p>

                    <p>
                        L’ensemble du site, sa structure, ses images, vidéos, sons et tout autre élément le
                        composant sont , sauf mention contraire, la propriété de la société Digimood.
                    </p>

                    <p>
                        Toute représentation totale ou partielle des éléments constituant ce site web, sans
                        l’autorisation express du représentant légal de la société Digimood constitue une
                        contrefaçon au sens des articles L 335-2 et suivants du code de la propriété
                        intellectuelle. Toute utilisation ou reproduction totale ou partielle des marques et
                        logos de la société Digimood, sans l’autorisation de son représentant légal
                        Jean-Marc FORTES, constitue également une contrefaçon au sens des articles L 335-2
                        et suivants du code de la propriété intellectuelle.
                    </p>

                    <h4>Liens hypertextes et référencement</h4>

                    <p>
                        L’équipe de Digimood veille à ce que les liens hypertextes postés sur le site et
                        notamment sur son blog fonctionnent, nous vous suggérons de signaler toute erreur et
                        dysfonction à l’équipe de l’agence via l’adresse mail suivante&nbsp;:&nbsp;
                        <strong>info@digimood.com</strong>
                    </p>

                    <p>
                        Nous acceptons de négocier des échanges de liens hypertextes cependant, toute
                        tentative et exécution de netlinking abusif vers les sites de la société DIGIMOOD
                        feront l’objet de poursuites judiciaires.
                    </p>

                    <h4>Limitation de responsabilité</h4>
                    <p>
                        Le directeur de publication et la société&nbsp;<strong>Digimood</strong>&nbsp;ne
                        peuvent voir leur responsabilité engagée pour un préjudice né de l’interprétation
                        d’éléments et d’information figurant sur les sites de la société Digimood.
                    </p>

                    <h4>Données personnelles</h4>
                    <p>
                        Les données personnelles recueillies depuis les sites de la société&nbsp;
                        <strong>DIGIMOOD</strong>&nbsp;sont, uniquement, volontairement transmises par les
                        utilisateurs qui demandent à être contactés par les professionnels de l’agence
                        Digimood. Le traitement de ces données ne doit donc pas faire l’objet d’une
                        déclaration auprès de la Commission nationale informatique et libertés
                    </p>

                    <p>
                        Les informations recueillies par le biais de l’un de nos formulaires de contact sont
                        enregistrées dans un fichier informatisé par notre agence pour faciliter les
                        échanges avec les prospects et clients de la société.<br/>Elles sont destinées à tout
                            gérant/employé de l’agence susceptible d’avoir un échange téléphonique ou par
                            messagerie électronique avec les personnes concernées, notamment pour répondre à
                            leur demande.
                    </p>

                    <p>
                        Aucune adresse IP n’est collecté lors de l’envoi d’un formulaire par le biais de
                        notre site Internet.
                    </p>

                    <p>Toutes ces données ne sont pas transmises à des tiers.</p>

                    <p>
                        Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6
                        janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout
                        utilisateur dispose d’un droit d’accès, de rectification, de suppression et
                        d’opposition aux données personnelles le concernant.
                    </p>

                    <p>Pour l’exercer, adressez votre demande par courrier à :</p>

                    <p><strong>Digimood</strong><br/>32 bis Avenue André Roussin</p>

                    <p>13016 Marseille</p>

                    <p>
                        La demande doit être écrite et signée, accompagnée d’une copie du titre d’identité
                        avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la
                        réponse doit être envoyée.
                    </p>

                    <p>
                        Aucune information personnelle de l’utilisateur du site www.digimood.com n’est
                        publiée à l’insu de l’utilisateur, échangée, transférée, cédée ou vendue sur un
                        support quelconque à des tiers.
                    </p>

                    <h4>Cookie, analytics et statistiques</h4>

                    <p>
                        Le présent site&nbsp;:&nbsp;<strong>www.digimood.com</strong>&nbsp;fait l’objet de
                        mesures et d’analyses afin d’offrir la meilleure expérience possible à ses
                        utilisateurs et à ses visiteurs.
                    </p>

                    <p>
                        Pour ce faire, un outil analytics installe un cookie sur le navigateur de
                        l’utilisateur afin de pouvoir collecter des données permettant à l’équipe de&nbsp;
                        <strong>Digimood</strong>&nbsp;de déterminer par quel biais et depuis quelle ville
                        l’internaute s’est connecté au site www.digimood.com.
                    </p>

                    <p>
                        Vous pouvez consulter les&nbsp;<a
                        href="https://marketingplatform.google.com/about/analytics/terms/fr/">conditions
                        générales Google Analytics</a>.
                    </p>

                    <p>
                        La plupart des navigateurs permettent de manager l’installation des cookies afin de
                        mieux protéger la vie privée de l’internaute. Tout visiteur des sites de l’agence
                        Digimood peut refuser l’installation des cookies envoyées, en paramétrant son
                        navigateur de manière à bloquer cette installation.
                    </p>

                    <p>
                        Il vous est également possible de désactiver la collecte d’informations par le biais
                        de la technologie des cookies. Pour cela, merci de consulter les pages suivantes
                        :
                    </p>
                    <ul>
                        <li>Mozilla Firefox :&nbsp;<a
                            href="https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent">https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent</a>
                        </li>
                        <li>Google Chrome :&nbsp;<a
                            href="https://support.google.com/chrome/answer/95647?hl=fr">https://support.google.com/chrome/answer/95647?hl=fr</a>
                        </li>
                    </ul>
                </div>
                <div className={'py-6 flex-none'}>
                    <Link to={'/login'} className={'block'}>Connexion</Link>
                </div>
            </div>
        </MinimalTemplate>
    )
}

export default TermsOfService