import React from "react";
import classNames from "classnames";

import {
    TableInstance,
    usePagination,
    UsePaginationInstanceProps,
    UsePaginationState,
    useSortBy,
    UseSortByInstanceProps,
    useTable,
    useExpanded
} from "react-table";
import {getTranslation} from "../../intl";

export interface AdvancedTableProps {
    columns: any
    data: any
    renderRowSubComponent: any
    showExpanded?: boolean
    mobileColumnsDisplayed?: number[]
    initialState?: any
}

export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
    UsePaginationInstanceProps<T> &
    UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
};

export function PaginateAdvancedTable(props: AdvancedTableProps) {
    let options: any = {
        columns: props.columns,
        data: props.data,
    }

    if (props.initialState) {
        options = {
            ...options,
            initialState: props.initialState
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        visibleColumns,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        options,
        useSortBy,
        useExpanded,
        usePagination,
    ) as TableInstanceWithHooks<AdvancedTableProps>;

    let sortedTitle = (column: any) => {
        if (column.isSorted) {
            if (column.isSortedDesc) {
                return getTranslation('REACT_TABLE.SORTED_DESC')
            }
            return getTranslation('REACT_TABLE.SORTED_ASC')
        }

        if (column.Header instanceof String) {
            return getTranslation('REACT_TABLE.SORTED').format([column.Header.toLowerCase()])
        }
    }

    let sortedIcon = (column: any) => {
        if (column.isSorted) {
            if (column.isSortedDesc) {
                return <i className="fa-solid fa-arrow-down-wide-short" />
            }
            return <i className="fa-solid fa-arrow-down-short-wide" />
        }

        return <></>
    }

    return (
        <div className={'lg:overflow-x-auto'}>
            <table className={'datatable table-auto'} {...getTableProps()}>
                <thead>
                {
                    headerGroups.slice(1, 2).map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map((column: any, idx: number) => {
                                    let cls = classNames(
                                        'text-left',
                                        {
                                            'hidden lg:table-cell': props.mobileColumnsDisplayed && props.mobileColumnsDisplayed.indexOf(idx) === -1
                                        }
                                    )
                                    // @ts-ignore
                                    return (
                                        <th className={cls} {...column.getHeaderProps(column.getSortByToggleProps())}
                                            title={sortedTitle(column)}
                                        >
                                            {column.render('Header')}
                                            <div className={'w-8 text-center inline-block'}>
                                                {sortedIcon(column)}
                                            </div>
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    ))
                }
                </thead>
                <tbody {...getTableBodyProps()}>
                {
                    page.map((row, i) => {
                        prepareRow(row)

                        if(props.showExpanded) {
                            // @ts-ignore
                            row.isExpanded = props.showExpanded
                        }
                        let className = classNames({
                            'bg-gray-100': i%2 === 0
                        })
                        return (
                            <React.Fragment key={i}>
                                <tr {...row.getRowProps()} className={className}>
                                    {
                                        row.cells.map((cell, idx) => {
                                            let cls = classNames(
                                                'text-left',
                                                {
                                                    'hidden lg:table-cell': props.mobileColumnsDisplayed && props.mobileColumnsDisplayed.indexOf(idx) === -1
                                                }
                                            )
                                            return <td className={cls} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        })
                                    }
                                </tr>
                                {
                                    // @ts-ignore
                                    row.isExpanded &&
                                    <tr className={classNames('hidden lg:table-row', className)}>
                                        <td colSpan={visibleColumns.length}>
                                            {props.renderRowSubComponent(row)}
                                        </td>
                                    </tr>
                                }
                                {
                                    // @ts-ignore
                                    row.isExpanded &&
                                    <tr className={classNames('table-row lg:hidden', className)}>
                                        <td colSpan={3}>
                                            <table className={'w-full'}>
                                                <tbody>
                                                {
                                                    props.mobileColumnsDisplayed &&
                                                    row.cells.filter((cell, idx) => props.mobileColumnsDisplayed!.indexOf(idx) === -1).map((cell, idx) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <td className={'font-bold'}>{cell.column.render('Header')}</td>
                                                                <td>{cell.render('Cell')}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                            {props.renderRowSubComponent(row)}
                                        </td>
                                    </tr>
                                }
                            </React.Fragment>
                        )
                    })
                }
                </tbody>
            </table>

            <TablePagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageCount={pageCount}
                gotoPage={gotoPage}
                nextPage={nextPage}
                previousPage={previousPage}
                setPageSize={setPageSize}
            />
        </div>
    )
}

export interface TablePaginationProps {
    pageIndex: number,
    pageSize: number,
    canPreviousPage: boolean,
    canNextPage: boolean,
    pageOptions: number[],
    pageCount: number,
    gotoPage: (updater: (number | ((pageIndex: number) => number))) => void,
    nextPage: () => void,
    previousPage: () => void,
    setPageSize: (pageSize: number) => void,
}


export function TablePagination(props: TablePaginationProps) {
    return (
        <div className="w-full flex items-center justify-center p-3 mt-5 relative">
            <div className={'w-60 flex items-center justify-center gap-x-2'}>
                <button
                    onClick={() => props.gotoPage(0)}
                    disabled={!props.canPreviousPage}
                    title={
                        props.canPreviousPage ?
                            getTranslation('REACT_TABLE_PAGINATION.FIRST_PAGE') : ''
                    }
                    className={
                        !props.canPreviousPage ?
                            'py-1 w-8 bg-slate-200 rounded-sm text-white' :
                            'py-1 w-8 bg-br-blue rounded-sm text-white'
                    }
                >
                    {'<<'}
                </button>

                <button
                    onClick={() => props.previousPage()}
                    disabled={!props.canPreviousPage}
                    title={
                        props.canPreviousPage ?
                            getTranslation('REACT_TABLE_PAGINATION.PREVIOUS_PAGE') : ''
                    }
                    className={
                        !props.canPreviousPage ?
                            'py-1 w-8 bg-slate-200 rounded-sm text-white' :
                            'py-1 w-8 bg-br-blue rounded-sm text-white'
                    }
                >
                    {'<'}
                </button>

                <span><strong>{props.pageIndex + 1} / {props.pageOptions.length}</strong></span>

                <button
                    onClick={() => props.nextPage()}
                    disabled={!props.canNextPage}
                    title={
                        props.canNextPage ?
                            getTranslation('REACT_TABLE_PAGINATION.NEXT_PAGE') : ''
                    }
                    className={
                        !props.canNextPage ?
                            'py-1 w-8 bg-slate-200 rounded-sm text-white' :
                            'py-1 w-8 bg-br-blue rounded-sm text-white'
                    }
                >
                    {'>'}
                </button>

                <button
                    onClick={() => props.gotoPage(props.pageCount - 1)}
                    disabled={!props.canNextPage}
                    title={
                        props.canNextPage ?
                            getTranslation('REACT_TABLE_PAGINATION.LAST_PAGE') : ''
                    }
                    className={
                        !props.canNextPage ?
                            'py-1 w-8 bg-slate-200 rounded-sm text-white' :
                            'py-1 w-8 bg-br-blue rounded-sm text-white'
                    }
                >
                    {'>>'}
                </button>
            </div>

            <div className={'hidden lg:block absolute right-0'}>
                <select
                    value={props.pageSize}
                    onChange={e => {
                        props.setPageSize(Number(e.target.value))
                    }}
                    className={'p-2'}
                >
                    {[100, 250, 500, 1000].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {
                                getTranslation('REACT_TABLE_PAGINATION.SHOW_DATA')
                                    .format(pageSize)
                            }
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}