import {Me} from "../../models";
import {MeFragment} from "./fragments";
import {CompanyFactory} from "../company/factory";
import {UserFactory} from "../user/factory";

export class MeFactory {
    static fromFragment = (fragment: MeFragment) : Me => {
        let me = new Me()
        me.id = fragment.id
        me.createdAt = new Date(fragment.created_at)
        me.position = fragment.position
        me.customers = fragment.customers
        me.company = CompanyFactory.fromFragment(fragment.company)
        me.user = UserFactory.fromFragment(fragment.user)
        return me
    }
}