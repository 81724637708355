import React, {useState} from "react";
import {JwtToken, Cache, PlaceHolder} from "@boomrank/react-components";
import {Account, Company, Identifier, Me, User} from "../../../models";
import {UserApi} from "../../../services/user/api";
import {UserUpdateResponse} from "../../../services/user/responses";
import {UserFactory} from "../../../services/user/factory";
import {AccountApi} from "../../../services/account/api";
import {useNavigate} from "react-router-dom";
import {DashboardAccountForm} from "../../forms/account";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    token: JwtToken
    account: Account
    cacheAccounts: Cache<Account>
    user: User
    cacheCompanies: Cache<Company>
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
}

export function DashboardAccountEditView(props: Props) {
    let navigate = useNavigate()
    let [isLoading, setIsLoading] = useState(false)
    let [error, setError] = useState('')
    let [success, setSuccess] = useState('')

    let onSubmit = (account: Account, user: User) => {
        setIsLoading(true);
        setSuccess('')
        setError('')

        UserApi.update(
            props.token,
            user
        ).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as UserUpdateResponse;
                let user = UserFactory.fromFragment(response.data);
                props.cacheUpdater(user)

                AccountApi.update(
                    props.token,
                    user,
                    account
                ).then((response) => {
                    if (response.statusCode >= 200 && response.statusCode < 300) {
                        let accounts = props.cacheAccounts.get([account.id])
                        if (accounts.length > 0) {
                            let oldAccount = {...accounts[0]}
                            oldAccount.customers.forEach((customer) => {
                                let companies = props.cacheCompanies.get([customer])
                                if (companies.length > 0) {
                                    let company = companies[0]
                                    company.experts = company.experts.filter(c => c !== customer)
                                }
                            })
                            props.cacheUpdater(account)
                        }
                        setSuccess(
                            getTranslation('VIEW.ACCOUNT.EDIT.SUCCESS')
                        )
                    } else {
                        setError(
                            getTranslation('VIEW.ACCOUNT.EDIT.ERROR')
                        )
                    }
                    setIsLoading(false)
                })
            } else {
                setError(
                    getTranslation('VIEW.ACCOUNT.EDIT.ERROR')
                )
                setIsLoading(false)
            }
        });
    }

    let onDelete = (account: Account, user: User) => {
        let confirm = window.confirm(
            getTranslation('VIEW.ACCOUNT.EDIT.CONFIRM_DELETE').format(user.email)
        )
        if(confirm) {
            setIsLoading(true)
            UserApi.delete(props.token, user).then((response) => {
                setIsLoading(false)
                props.cacheUpdater(user, true)
                props.cacheUpdater(account, true)
                navigate('/dashboard/accounts/')
            })
        }
    }

    return (
        <>
            <h2 className={'mb-6'}>
                {props.user.firstname} {props.user.lastname}
            </h2>
            {
                isLoading &&
                <PlaceHolder className={'h-32'} />
            }
            {
                error &&
                <div role={'alert'} className={'p-6 text-br-red border border-br-red bg-red-50 my-3'}>
                    {error}
                </div>
            }
            {
                success &&
                <div role={'alert'} className={'p-6 text-br-green border border-br-green bg-green-50 my-3'}>
                    {success}
                </div>
            }
            <DashboardAccountForm
                me={props.me}
                account={props.account}
                update={true}
                onSubmit={onSubmit}
                onDelete={onDelete}
                companies={props.cacheCompanies.values()}
                user={props.user}
                disabled={isLoading || !props.me.company.isAdmin}
            />
        </>
    )
}