export interface Identifier {
    id: number
}

export class User implements Identifier {
    id: number = 0
    email: string = ''
    firstname: string = ''
    lastname: string = ''
    isAdmin: boolean = false
    isActive: boolean = false
}

export class Account implements Identifier {
    id: number = 0
    user: number = 0
    company: number = 0
    createdAt: Date = new Date()
    position: string = ''
    customers: number[] = []
}

export class Me implements Identifier {
    id: number = 0
    user: User = new User()
    company: Company = new Company()
    createdAt: Date = new Date()
    position: string = ''
    customers: number[] = []
}

export class Company implements Identifier {
    id: number = 0
    name: string = ''
    isAdmin: boolean = false
    logo: string = ''
    employees: number[] = []
    experts: number[] = []
    services: number[] = []
}

export class Service implements Identifier  {
    id: number = 0
    logo: string = ''
    url: string = ''
    description: string = ''
    clientId: string = ''
    name: string = ''
}