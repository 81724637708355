import {BlueButton, GrayButton} from "@boomrank/react-components";
import {Link} from "react-router-dom";
import {useState} from "react";
import {getTranslation} from "../../intl";

interface Props {
    onSubmit: (email: string) => void
    disabled?: boolean
}

export function AuthPasswordForgottenForm(props: Props) {
    let [email, setEmail] = useState('')
    let [errorEmail, setErrorEmail] = useState('')

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    let onSubmit = () => {
        let isValid = true
        setErrorEmail('')

        if (email.length === 0) {
            isValid = false
            setErrorEmail(
                getTranslation('FORM.FORGOTTEN_PASSWORD.FILL_LOGIN_FIELD')
            )
        }

        if (isValid) {
            props.onSubmit(email)
        }
    }

    let onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key.toLowerCase() === 'enter') {
            onSubmit();
        }
    }

    return (
        <>
            <div className={'text-left px-6'}>
                <label className={'w-full block font-GothamBook leading-10 text-black'}>
                    {
                        getTranslation('FORM.FORGOTTEN_PASSWORD.LABEL_LOGIN')
                    }
                </label>
                <input
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    value={email}
                    name={'email'}
                    className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                    disabled={props.disabled || false}
                />
                {
                    errorEmail &&
                    <div role={'alert'} className={'text-br-red py-3'}>
                        {errorEmail}
                    </div>
                }
            </div>
            <div className={'pt-6'}>
                {
                    !props.disabled ?
                        <BlueButton className={'mx-auto'} text={getTranslation('FORM.FORGOTTEN_PASSWORD.SUBMIT_LABEL')} onClick={() => {
                            onSubmit();
                        }}/>
                    :
                        <GrayButton className={'mx-auto'} text={getTranslation('FORM.FORGOTTEN_PASSWORD.SUBMIT_LABEL')}/>
                }

            </div>
            <div className={'pt-6'}>
                <Link to={'/login'} className={'block'}>
                    {
                        getTranslation('FORM.FORGOTTEN_PASSWORD.CONNEXION')
                    }
                </Link>
            </div>
        </>
    )
}