import {AuthLoginView} from "../views/login";
import {JwtToken} from "@boomrank/react-components";
import {getTranslation} from "../../intl";

interface Props {
    token: JwtToken
    onLogin: (token: JwtToken) => void
}

export function AuthLoginRoute(props: Props) {
    document.title = `${getTranslation('ROUTE.AUTH.LOGIN.TITLE')} | Boomrank.io`

    return (
        <AuthLoginView onLogin={props.onLogin} token={props.token}/>
    )
}