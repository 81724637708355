import {Link, useLocation} from "react-router-dom";
import {Breadcrumb, JwtToken} from "@boomrank/react-components";
import React from "react";
import {DashboardProfileEditView} from "../../views/profile/edit";
import {Identifier, Me} from "../../../models";
import {getTranslation} from "../../../intl";

interface Props {
    token: JwtToken
    me: Me
    cacheUpdater: (obj: Identifier) => void
}

export function DashboardProfileRoute(props: Props) {
    let location = useLocation()
    document.title = `${getTranslation('ROUTE.PROFILE.TITLE')} | Boomrank.io`

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content:<i className={'fa-solid fa-home'}/>, title: getTranslation('BREADCRUMB.HOMEPAGE')},
                    {to: '/dashboard/profile/', content: getTranslation('BREADCRUMB.PROFILE')},
                ]}
            />
            <div className={'mt-3'}>
                <div className={'flex flex-row-reverse'}>
                    <Link to={'/dashboard/profile/password/'} className={'btn btn-blue'}>
                        {
                            getTranslation('ROUTE.PROFILE.LABEL_CHANGE_PASSWORD')
                        }
                    </Link>
                </div>
                <DashboardProfileEditView token={props.token}  cacheUpdater={props.cacheUpdater} me={props.me}/>
            </div>
        </>
    )
}