import {Company, Identifier, Me} from "../../../models";
import {Breadcrumb, Cache, JwtToken, useQuery} from "@boomrank/react-components";
import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {DashboardAccountAddView} from "../../views/account/add";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    token: JwtToken
    cacheCompanies: Cache<Company>
    cacheUpdater: (obj: Identifier) => void
}

export function DashboardAccountAddRoute(props: Props) {
    let [company, setCompany] = useState<Company | undefined>(undefined)
    let location = useLocation()
    let query = useQuery()
    document.title = `${getTranslation('ROUTE.ACCOUNT.ADD.TITLE')} | Boomrank.io`

    let cid = query.get('cid')
    useEffect(() => {
        if (cid) {
            let companies = props.cacheCompanies.get([cid])
            if (companies.length > 0) {
                setCompany(companies[0])
            } else {
                setCompany(undefined)
            }
        }
    }, [cid, props.cacheCompanies])

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content:<i className={'fa-solid fa-home'}/>, title: getTranslation('BREADCRUMB.HOMEPAGE')},
                    {to: '/dashboard/accounts/', content: getTranslation('BREADCRUMB.ACCOUNTS')},
                    {to: '/dashboard/accounts/add/', content: getTranslation('BREADCRUMB.NEW_ACCOUNT')},
                ]}
            />
            <div className={'mt-3'}>
                <DashboardAccountAddView
                    cacheCompanies={props.cacheCompanies}
                    company={company}
                    cacheUpdater={props.cacheUpdater}
                    me={props.me}
                    token={props.token}
                />
            </div>
        </>
    )
}