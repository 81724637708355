import {Route, Routes} from "react-router-dom";
import {JwtToken, MinimalTemplate, PrivateRoute, Firewall, AuthLogoutRoute} from "@boomrank/react-components";
import {AuthLoginRoute} from "./routes/login";
import {AuthAuthorizeRoute} from "./routes/authorize";
import {AuthPasswordForgottenRoute} from "./routes/password-forgotten";

interface Props {
    token: JwtToken
    onCheck: () => void
    onLogin: (token: JwtToken) => void
    onLogout: () => void
}

export function Auth(props: Props) {
    return (
        <MinimalTemplate>
            <Routes>
                <Route path={'/authorize/*'} element={
                    <PrivateRoute token={props.token}>
                        <Firewall token={props.token} onCheck={props.onCheck}>
                            <Routes>
                                <Route index element={ <AuthAuthorizeRoute token={props.token} /> } />
                            </Routes>
                        </Firewall>
                    </PrivateRoute>
                }/>
                <Route path={'/login'} element={
                    <AuthLoginRoute onLogin={props.onLogin} token={props.token} />
                }/>
                <Route path={'/logout'} element={
                    <AuthLogoutRoute
                        onLogout={props.onLogout}
                        keyStorage={process.env.REACT_APP_KEY_STORAGE!}
                        redirect={'/'}
                    />
                }/>
                <Route path={'/*'} element={<AuthPasswordForgottenRoute />}/>
            </Routes>
        </MinimalTemplate>
    )
}