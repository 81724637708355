import {endpoints} from "../api";
import {AuthorizationResponseSuccess} from "./responses";
import {Api, JwtToken, Response} from "@boomrank/react-components";


export class OAuth2Api {
    static getAuthorize(token: JwtToken, queries: string) : Promise<Response | AuthorizationResponseSuccess> {
        let url = endpoints.oauth2.authorize + queries.toString();
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }
}