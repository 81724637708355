import {AccountFragment} from "./fragments";
import {Account} from "../../models";

export class AccountFactory {
    static fromFragment = (fragment: AccountFragment): Account => {
        let account = new Account()
        account.id = fragment.id
        account.createdAt = new Date(fragment.created_at)
        account.position = fragment.position
        account.user = fragment.user
        account.company = fragment.company
        account.customers = fragment.customers
        return account
    }
}
