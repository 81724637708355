import {Account, Identifier, Me, Service, User} from "../../../models";
import {Breadcrumb, Cache, JwtToken} from "@boomrank/react-components";
import {useLocation} from "react-router-dom";
import React from "react";
import {DashboardCompanyAddView} from "../../views/company/add";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    token: JwtToken
    accounts: Account[]
    services: Service[]
    cacheUsers: Cache<User>
    cacheUpdater: (obj: Identifier) => void
}

export function DashboardCompanyAddRoute(props: Props) {
    let location = useLocation()
    document.title = `${getTranslation('ROUTE.COMPANY.ADD.TITLE')} | Boomrank.io`

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content:<i className={'fa-solid fa-home'}/>, title: getTranslation('BREADCRUMB.HOMEPAGE')},
                    {to: '/dashboard/companies/', content: getTranslation('BREADCRUMB.COMPANIES')},
                    {to: '/dashboard/companies/add/', content: getTranslation('BREADCRUMB.NEW_COMPANY')},
                ]}
            />
            <div className={'mt-3'}>
                <DashboardCompanyAddView
                    accounts={props.accounts}
                    me={props.me}
                    token={props.token}
                    cacheUpdater={props.cacheUpdater}
                    cacheUsers={props.cacheUsers}
                    services={props.services}
                />
            </div>
        </>
    )
}