let BACKEND_API = process.env.REACT_APP_BACKEND_API

export const endpoints = {
    auth: {
        access_token: BACKEND_API+'/api/token/',
        refresh_token: BACKEND_API+'/api/token/refresh/',
        verify_token: BACKEND_API+'/api/token/verify/',
        password_reset: BACKEND_API+'/api/password/reset/',
        password_change: BACKEND_API+'/api/password/change/',
        me: BACKEND_API+'/api/account/me/',
    },
    oauth2: {
        authorize: BACKEND_API+'/api/oauth2/authorize/',
    },
    google: {
        folder: BACKEND_API+'/api/google/folder/',
    },
    user: {
        list: BACKEND_API+'/api/user/',
        create: BACKEND_API+'/api/user/',
        retrieve: BACKEND_API+'/api/user/:pk/',
        update: BACKEND_API+'/api/user/:pk/',
        delete: BACKEND_API+'/api/user/:pk/',
    },
    account: {
        list: BACKEND_API+'/api/account/',
        create: BACKEND_API+'/api/account/',
        retrieve: BACKEND_API+'/api/account/:pk/',
        update: BACKEND_API+'/api/account/:pk/',
        delete: BACKEND_API+'/api/account/:pk/',
    },
    company: {
        list: BACKEND_API+'/api/company/',
        create: BACKEND_API+'/api/company/',
        retrieve: BACKEND_API+'/api/company/:pk/',
        update: BACKEND_API+'/api/company/:pk/',
        delete: BACKEND_API+'/api/company/:pk/',
        upload: BACKEND_API+'/api/company/:pk/upload_logo/',
    },
    service: {
        list: BACKEND_API+'/api/service/',
        retrieve: BACKEND_API+'/api/service/:pk/',
    },
    application: {
        list: BACKEND_API+'/api/application/',
        retrieve: BACKEND_API+'/api/application/:pk/',
    },
    group: {
        list: BACKEND_API+'/api/group/',
    },
    file: {
        retrieve: BACKEND_API+'/api/file/:pk/',
    }
}