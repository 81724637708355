import {BlueButton, GrayButton} from "@boomrank/react-components";
import React, {useState} from "react";
import {getTranslation} from "../../../intl";

interface Props {
    onSubmit: (password: string) => void
    disabled?: boolean
}

export function DashboardProfileChangePasswordForm(props: Props) {
    let [password1, setPassword1] = useState('')
    let [password2, setPassword2] = useState('')
    let [error, setError] = useState('')

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'password1') {
            setPassword1(event.target.value)
        }
        if (event.target.name === 'password2') {
            setPassword2(event.target.value)
        }
    }

    let onSubmit = () => {
        let isValid = true
        setError('')

        if(password1 !== password2) {
            isValid= false
            setError(
                getTranslation('FORM.PROFILE.CHANGE_PASSWORD.ERROR')
            )
        }
        if(password1.length === 0 || password2.length === 0) {
            isValid= false
            setError(
                getTranslation('FORM.PROFILE.CHANGE_PASSWORD.NOT_EMPTY')
            )
        }

        if (isValid) {
            props.onSubmit(password1)
        }
    }
    return (
        <div>
            {
                error &&
                <div role={'alert'} className={'p-6 text-br-red border border-br-red bg-red-50 my-3'}>
                    {error}
                </div>
            }
            <div className={'text-left px-6'}>
                <label className={'w-full block font-GothamBook leading-10 text-black'}>
                    {getTranslation('FORM.PROFILE.CHANGE_PASSWORD.LABEL_PASSWORD_1')}
                </label>
                <input onChange={onChange}
                       value={password1}
                       name={'password1'}
                       type={'password'}
                       className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                       disabled={props.disabled || false}
                       data-testid={'password'}
                />
            </div>
            <div className={'text-left px-6'}>
                <label className={'w-full block font-GothamBook leading-10 text-black'}>
                    {getTranslation('FORM.PROFILE.CHANGE_PASSWORD.LABEL_PASSWORD_2')}
                </label>
                <input onChange={onChange}
                       type={'password'}
                       value={password2}
                       name={'password2'}
                       className={'w-full p-4 border-gray border text-gray font-GothamBook'}
                       disabled={props.disabled || false}
                       data-testid={'password'}
                />
            </div>
            <div className={'pt-6 text-center'}>
                {
                    props.disabled ?
                        <GrayButton text={getTranslation('FORM.PROFILE.CHANGE_PASSWORD.LABEL_SUBMIT')} />
                    :
                        <BlueButton text={getTranslation('FORM.PROFILE.CHANGE_PASSWORD.LABEL_SUBMIT')} onClick={onSubmit} />
                }
            </div>
        </div>
    )
}