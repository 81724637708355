import React from "react";
import {Company} from "../../../models";
import {getTranslation} from "../../../intl";

interface Props {
    company: Company
    onFileChange: (files: FileList) => void
    disabled?: boolean
}

export function DashboardCompanyUploadForm(props: Props) {
    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.target.files
        if (files && files.length > 0) {
            props.onFileChange(files)
        }
    }

    return (
        <>
            <div className={'text-center'}>
                {
                    props.company.logo &&
                    <img src={`${props.company.logo}?rng=${Math.random()}`}
                         alt={props.company.name}
                         className={'h-32 w-min-0 inline-block border p-3'}
                    />
                }
                {
                    !props.company.logo &&
                    <div className={'p-6 block bg-gray-100 text-center text-gray-500'}>
                        {
                            getTranslation('FORM.COMPANY.UPLOAD.NO_LOGO')
                        }
                    </div>
                }
                <div className={'mt-6'}>
                    {
                        props.disabled ?
                            <label
                                htmlFor={'company_upload'}
                                className={'btn btn-gray'}
                            >
                                {
                                    getTranslation('FORM.COMPANY.UPLOAD.LABEL_CHANGE_LOGO')
                                }
                            </label>
                        :
                            <label
                                htmlFor={'company_upload'}
                                className={'btn btn-blue cursor-pointer'}
                            >
                                {
                                    getTranslation('FORM.COMPANY.UPLOAD.LABEL_CHANGE_LOGO')
                                }
                            </label>
                    }
                    <input type={"file"}
                           id={'company_upload'}
                           onChange={onChange}
                           className={'hidden'}
                           disabled={props.disabled || false}
                           data-testid={'company_upload'}
                    />
                </div>
            </div>
        </>
    )
}