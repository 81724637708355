import {Company} from "../../models";
import classNames from "classnames";

export interface Props {
    company: Company
    headline?: JSX.Element | JSX.Element[] | string
    header?: JSX.Element
    className?: string
    onClick?: () => void
}
export function CompanyCard(props: Props) {
    let className = classNames(
        'rounded shadow-md ring-1 ring-gray-300',
        props.className,
        {
            'cursor-pointer hover:ring-br-blue': props.onClick,
        }
    )

    let header = props.header
    if (!header) {
        header = <>{props.company.name.slice(0, 2).toUpperCase()}</>
    }

    let styles = {}
    if (props.company.logo) {
        styles = {
            backgroundImage: `url('${props.company.logo}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'right'
        }
        header = <></>
    }

    let onClick = () => {
        if (props.onClick) {
            props.onClick()
        }
    }

    return (
        <div className={className} onClick={onClick} role={'button'}>
            <div  style={styles}
                  className={'rounded-t text-xl text-white text-center bg-br-blue h-24 flex items-center justify-center'}>
                {
                    header &&
                    <>
                        {header}
                    </>
                }
            </div>
            <div className={'rounded-b p-3'}>
                <div className={'flex'}>
                    <div>
                        <div className="rounded-full bg-br-blue text-white">
                            <div className={'py-4 w-14 text-center'}>
                                { props.company.name.slice(0, 2).toUpperCase() }
                            </div>
                        </div>
                    </div>
                    <div className={'pl-3 flex-grow'}>
                        <div className={'font-medium'}>{props.company.name}</div>
                        {
                            props.headline &&
                            <div className={'text-sm text-gray-600'}>
                                {props.headline}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
