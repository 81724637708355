import {Breadcrumb, JwtToken} from "@boomrank/react-components";
import React from "react";
import {useLocation} from "react-router-dom";
import {DashboardProfileChangePasswordView} from "../../views/profile/change-password";
import {getTranslation} from "../../../intl";

interface Props {
    token: JwtToken
}

export function DashboardProfileChangePasswordRoute(props: Props) {
    let location = useLocation()
    document.title = `${getTranslation('ROUTE.PROFILE.CHANGE_PASSWORD.TITLE')} | Boomrank.io`

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content:<i className={'fa-solid fa-home'}/>, title: getTranslation('BREADCRUMB.HOMEPAGE')},
                    {to: '/dashboard/profile/', content: getTranslation('BREADCRUMB.PROFILE')},
                    {to: '/dashboard/profile/password/', content: getTranslation('BREADCRUMB.EDIT_PASSWORD')},
                ]}
            />
            <div className={'mt-3'}>
                <DashboardProfileChangePasswordView token={props.token} />
            </div>
        </>
    )
}