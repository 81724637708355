import {useLocation} from "react-router-dom";
import {Breadcrumb, Cache} from "@boomrank/react-components";
import React from "react";
import {Account, Company, Me, Service, User} from "../../models";
import {DashboardHomeView} from "../views/home";
import {getTranslation} from "../../intl";

interface Props {
    me: Me
    cacheServices: Cache<Service>
    cacheAccounts: Cache<Account>
    cacheUsers: Cache<User>
    cacheCompanies: Cache<Company>
}

export function DashboardHomeRoute(props: Props) {
    let location = useLocation()
    document.title = `${getTranslation('ROUTE.HOME.TITLE')} | Boomrank.io`

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content:<i className={'fa-solid fa-home'}/>, title: getTranslation('BREADCRUMB.HOMEPAGE')},
                ]}
            />
            <div className={'mt-3'}>
                <DashboardHomeView
                    me={props.me}
                    cacheAccounts={props.cacheAccounts}
                    cacheCompanies={props.cacheCompanies}
                    cacheServices={props.cacheServices}
                    cacheUsers={props.cacheUsers}
                />
            </div>
        </>
    )
}