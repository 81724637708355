import React, {useEffect, useState} from "react";
import {DashboardAccountBasisForm} from "./basis";
import {DashboardAccountCustomersForm} from "./customers";
import {GrayButton, BlueButton} from "@boomrank/react-components";
import {Company, Me, User, Account} from "../../../models";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    account: Account
    user: User
    company?: Company
    companies: Company[]
    update: boolean
    onSubmit: (account: Account, user: User) => void
    onDelete?: (account: Account, user: User) => void
    disabled?: boolean
}

export function DashboardAccountForm(props: Props) {
    let [account, setAccount] = useState<Account>(props.account);
    let [user, setUser] = useState<User>(props.user);

    useEffect(() => {
        setAccount(props.account)
        setUser(props.user)
    }, [props.account, props.user])

    let onDelete = () => {
        if (props.onDelete) {
            props.onDelete(account, user)
        }
    }

    let onInputChange = (name: string, value: string) => {
        if (['email', 'firstname', 'lastname'].indexOf(name) !== -1) {
            setUser({
                ...user,
                [name]: value
            })
        }
        if (['position'].indexOf(name) !== -1) {
            setAccount({
                ...account,
                [name]: value
            })
        }
    }

    let onCompanyChange = (c: Company) => {
        let customers = [...account.customers];
        if (customers.indexOf(c.id) !== -1) {
            customers.splice(customers.indexOf(c.id), 1)
        }

        setAccount({
            ...account,
            company: c.id,
            customers: customers
        })
    }

    let onStatusChange = (isActive: boolean) => {
        setUser({
            ...user,
            isActive: isActive
        })
    }

    let onCustomerClick = (company: Company) => {
        let customers = [...account.customers];

        let index = customers.indexOf(company.id)
        if (index === -1) {
            customers.push(company.id)
        } else {
            customers.splice(index, 1)
        }
        setAccount({
            ...account,
            customers: customers
        })
    }

    let onSubmit = () => {
        props.onSubmit(account, user)
    }

    return (
        <>
            <div className={'flex flex-row-reverse'}>
                {
                    (
                        props.me.user.isAdmin &&
                        props.me.user.id !== props.user.id &&
                        props.update
                    ) &&
                    <>
                        {
                            props.disabled ?
                                <GrayButton
                                    text={
                                        <i className={'fa-solid fa-trash-can'}/>
                                    }
                                />
                                :
                                <GrayButton
                                    text={
                                        <i className={'fa-solid fa-trash-can'}/>
                                    }
                                    onClick={() => {
                                        onDelete()
                                    }}
                                />
                        }
                    </>
                }
            </div>
            <div className={'lg:grid grid-cols-2 gap-3'}>
                <div>
                    <DashboardAccountBasisForm
                        account={account}
                        user={user}
                        onInputChange={onInputChange}
                        onCompanyChange={onCompanyChange}
                        onStatusChange={onStatusChange}
                        company={props.company}
                        companies={props.companies}
                        disabled={props.disabled}
                    />
                </div>
                <div>
                    {
                        props.me.company.isAdmin &&
                        <>
                            <DashboardAccountCustomersForm
                                account={account}
                                onCustomerClick={onCustomerClick}
                                companies={props.companies}
                                disabled={props.disabled}
                            />
                        </>
                    }
                </div>
            </div>
            <div className={'pt-6 text-center'}>
                {
                    props.disabled ?
                        <GrayButton className={'mx-auto'} text={
                            getTranslation('FORM.COMPANY.LABEL_SUBMIT')
                        }/>
                    :
                        <BlueButton className={'mx-auto'} text={
                            getTranslation('FORM.COMPANY.LABEL_SUBMIT')
                        } onClick={onSubmit} />
                }
            </div>
        </>
    )
}