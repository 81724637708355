import {endpoints} from "../api";
import {AccountCreateResponse, AccountListResponse, AccountRetrieveResponse, AccountUpdateResponse} from "./responses";
import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {User, Account} from "../../models";

export class AccountApi {
    static list(token: JwtToken, baseUrl?: string): Promise<FormErrorResponse | AccountListResponse> {
        let url = endpoints.account.list;
        if (baseUrl) {
            url = baseUrl;
        }
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static retrieve(token: JwtToken, id: number): Promise<FormErrorResponse | AccountRetrieveResponse> {
        let url = endpoints.account.retrieve.replace(':pk', id.toString());
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static create(token: JwtToken, user: User, account: Account): Promise<FormErrorResponse | AccountCreateResponse> {
        let url = endpoints.account.create;
        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    user: user.id,
                    company: account.company,
                    customers: account.customers,
                    position: account.position,
                })
            }
        );
    }

    static update(
        token: JwtToken,
        user: User,
        account: Account
    ): Promise<FormErrorResponse | AccountUpdateResponse> {
        let url = endpoints.account.update.replace(':pk', account.id.toString());
        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    'company': account.company,
                    'customers': account.customers,
                    'position': account.position,
                    'user': user.id,
                })
            }
        );
    }

    static delete(token: JwtToken, account: Account): Promise<any> {
        let url = endpoints.account.delete.replace(':pk', account.id.toString());
        return Api.call(
            url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }
}