import logo from '@boomrank/web-assets/public/images/logo/boomrank_450x200.png';
import {RedButton, GreenButton, SecureHTML} from "@boomrank/react-components";
import {Service} from "../../models";
import {getTranslation} from "../../intl";

interface Props {
    service: Service
    scopes: string[]
    onSubmit: () => void
    onCancel: () => void
    disabled?: boolean
}

// authorize/?client_id=fth7MQBZvo2khc4uXA6QHitCRGxHvVHPvDk7yxD3&scopes=plans%20profile&response_type=code&redirect_uri=https://boomrank.io/&code_challenge=XRi41b-5yHtTojvCpXFpsLUnmGFz6xR15c3vpPANAvM
export function AuthAuthorizeForm(props: Props) {
    let scopes = ['profile', 'plans']

    let onSubmit = () => {
        props.onSubmit()
    }

    let onCancel = () => {
        props.onCancel()
    }

    return (
        <>
            <div className={'text-center mb-6'}>
                <img src={logo} alt={'digimood'} className={'mx-auto w-1/2'} />
                <div className={'pt-0 p-6 text-2xl text-gray-500'}>X</div>
                <img src={props.service.logo} alt={props.service.name} className={'mx-auto w-1/4'} />
            </div>
            {
                props.service.description.length > 0 &&
                <div className={'text-justify mx-3 mb-6 overflow-y-auto h-24'}>
                    {props.service.description}
                </div>
            }

            <div className={'text-center mx-3'}>
                <SecureHTML>
                    { getTranslation('FORM.AUTHORIZATION.NEED_PERMISSIONS').format(props.service.name) }
                </SecureHTML>
            </div>
            {
                props.scopes.length > 0 &&
                <div className={'mt-12 mx-3 text-left'}>
                    <h4>
                        { getTranslation('FORM.AUTHORIZATION.DISCLAIMER').format(props.service.name) }
                    </h4>
                    <ul className={'mt-6 border border-x-0 border-b-0 border-gray-150'}>
                        {
                            props.scopes.filter(s => scopes.indexOf(s) !== -1).map((scope: string, index) => {
                                return (
                                    <li key={index} className={'p-6 border border-x-0 border-t-0 border-gray-150 text-sm flex items-center'}>
                                        <i className="fa-solid mr-6 fa-circle-check text-br-blue align-items" />
                                        <div>{ getTranslation(`FORM.AUTHORIZATION.SCOPES.${scope.toUpperCase()}`) }</div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            }

            <div className={'mt-12 flex justify-between'}>
                <RedButton text={getTranslation('FORM.AUTHORIZATION.DECLINE')} onClick={() => {
                    onCancel()
                }}/>
                <GreenButton text={getTranslation('FORM.AUTHORIZATION.AUTHORIZE')} onClick={() => {
                    onSubmit()
                }}/>
            </div>
        </>
    )
}
