import {Link} from "react-router-dom";
import {Me} from "../../../models";
import {FeedbackTypeformPopover, ProfileMenu, DashboardTemplate, SecureHTML} from "@boomrank/react-components";
import {v4} from "uuid";
import logo from '@boomrank/web-assets/public/images/logo/boomrank_450x200.png';
import React from "react";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    children?: React.ReactElement[] | React.ReactElement
}

export function Template(props: Props) {
    let getProfileMenuItems = () => {
        let items = []

        items.push(
            <Link className={'hover:text-br-blue-700'} to={'/dashboard/profile/'}>
                { getTranslation('TEMPLATE.PROFILE') }
            </Link>
        )
        items.push(
            <Link className={'hover:text-br-blue-700'} to={'/dashboard/accounts/'}>
                { getTranslation('TEMPLATE.ACCOUNTS') }
            </Link>
        )
        if (props.me.company.isAdmin) {
            items.push(
                <Link className={'hover:text-br-blue-700'} to={'/dashboard/companies/'}>
                    { getTranslation('TEMPLATE.COMPANIES') }
                </Link>
            )
        }
        items.push(
            <Link className={'hover:text-br-blue-700'} to={'/dashboard/companies/edit/' + props.me.company.id}>
                {props.me.company.name}
            </Link>
        )
        items.push(
            <Link className={'hover:text-br-blue-700'} to={'/logout'}>
                { getTranslation('TEMPLATE.LOGOUT') }
            </Link>
        )
        return items
    }

    let menuItems = [
        <ProfileMenu
            key={v4()}
            items={getProfileMenuItems()}
            user={props.me.user}
        />
    ]
    if (props.me.user.email.indexOf('@digimood.com') !== -1) {
        menuItems.unshift(
            <a href={'https://faq.digimood.io/docs/documentation-seo/boomrank-9905/'}
               target={'_blank'}
               key={v4()}
               title={
                   getTranslation('TEMPLATE.NEED_HELP')
               }
               className={'mr-4 py-4 w-14 text-center rounded-full bg-br-green text-white cursor-pointer no-underline'}
            >
                <i className="fa-regular fa-circle-question" />
            </a>,
        )
    }

    return (
        <DashboardTemplate
            headline={
                <h1>
                    <SecureHTML>
                        { getTranslation('TEMPLATE.HELLO').format(props.me.user.firstname) }
                    </SecureHTML>
                </h1>
            }
            logo={
                <>
                    <Link to={'/dashboard/'}>
                        <img className={'w-32'} src={logo} title="Boomrank" alt="Boomrank"/>
                    </Link>
                    <span className={'m-4 text-gray-300'}>X</span>
                    <div className="flex items-stretch">
                        <Link to={'/dashboard/'} className={'self-center'}>
                            <img className={'max-w-32 max-h-14'}
                                 src={`${props.me.company.logo}?rng=${Math.random()}`}
                                 title={props.me.company.name}
                                 alt={props.me.company.name}
                            />
                        </Link>
                    </div>
                </>
            }
            menuItems={menuItems}
        >
            <>
                { props.children }
            </>
            {
                <div className={'text-xs text-br-blue-500 text-right'}>
                    build #{process.env.REACT_APP_RELEASE_BUILD} - {process.env.REACT_APP_RELEASE_DATE}
                    {
                        props.me.company.isAdmin &&
                        <FeedbackTypeformPopover
                            id={'b6tP5J7P'}
                            hidden={{
                                'email': props.me.user.email,
                                'firstname': props.me!.user.firstname,
                                'url': window.location.href,
                                'application': `boomrank`,
                                'build': `${process.env.REACT_APP_RELEASE_BRANCH}-${process.env.REACT_APP_RELEASE_BUILD}`,
                            }}
                            autoClose={3000}
                        />
                    }
                </div>
            }
        </DashboardTemplate>
    )
}