import {endpoints} from "../api";
import {CompanyCreateResponse, CompanyListResponse, CompanyRetrieveResponse, CompanyUpdateResponse, CompanyUploadResponse} from "./responses";
import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {Company} from "../../models";

export class CompanyApi {
    static list(token: JwtToken, baseUrl?: string): Promise<FormErrorResponse | CompanyListResponse> {
        let url = endpoints.company.list;
        if (baseUrl) {
            url = baseUrl;
        }
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static retrieve(token: JwtToken, id: number): Promise<FormErrorResponse | CompanyRetrieveResponse> {
        let url = endpoints.company.retrieve.replace(':pk', id.toString());
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static create(token: JwtToken, company: Company): Promise<FormErrorResponse | CompanyCreateResponse> {
        let url = endpoints.company.create;
        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    name: company.name,
                    is_admin: company.isAdmin,
                })
            }
        );
    }

    static update(token: JwtToken, company: Company): Promise<FormErrorResponse | CompanyUpdateResponse> {
        let url = endpoints.company.update.replace(':pk', company.id.toString());
        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    name: company.name,
                    is_admin: company.isAdmin,
                    services: company.services
                })
            }
        );
    }

    static delete(token: JwtToken, company: Company): Promise<any> {
        let url = endpoints.company.delete.replace(':pk', company.id.toString());
        return Api.call(
            url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static upload(token: JwtToken, company: Company, file: File | null): Promise<FormErrorResponse | CompanyUploadResponse> {
        let url = endpoints.company.upload.replace(':pk', company.id.toString());

        let form = new FormData()
        if(file) {
            form.append('logo', file)
        }

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token.access}`,
                },
                body: form
            }
        );
    }
}