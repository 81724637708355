import {LANG_FR} from '@boomrank/react-components'

const DEFAULT_ERROR = 'Une erreur est survenue.'
export const LANG = {
    ...LANG_FR,
    FORM: {
        AUTHORIZATION: {
            NEED_PERMISSIONS: '<b>{0}</b> souhaite avoir accès à votre compte Digimood.',
            DISCLAIMER: 'J\'autorise {0} à :',
            AUTHORIZE: 'Autoriser',
            DECLINE: 'Refuser',
            SCOPES: {
                PLANS: 'Accèder à vos abonnements Digimood.',
                PROFILE: 'Consulter vos informations personnelles.'
            }
        },
        LOGIN: {
            LABEL_LOGIN: 'Email',
            LABEL_PASSWORD: 'Mot de passe',
            SUBMIT_LABEL: 'S\'authentifier',
            FILL_LOGIN_FIELD: 'Veuillez saisir une adresse email.',
            FILL_PASSWORD_FIELD: 'Veuillez saisir un mot de passe.',
            TERMS_LABEL: 'En vous authentifiant, vous acceptez nos {0}',
            TERMS_OF_SERVICES: 'Conditions Générales',
            FORGOTTEN_PASSWORD: 'Mot de passe oublié?',
        },
        FORGOTTEN_PASSWORD: {
            LABEL_LOGIN: 'Email',
            SUBMIT_LABEL: 'Réinitialiser le mot de passe',
            FILL_LOGIN_FIELD: 'Veuillez saisir une adresse email.',
            CONNEXION: 'Connexion',
        },
        PROFILE: {
            EDIT: {
                LABEL_FIRSTNAME: 'Prénom',
                LABEL_LASTNAME: 'Nom',
                LABEL_EMAIL: 'Email',
                LABEL_POSITION: 'Job',
                LABEL_SUBMIT: 'Modifier',
            },
            CHANGE_PASSWORD: {
                LABEL_PASSWORD_1: 'Nouveau mot de passe',
                LABEL_PASSWORD_2: 'Confirmation du nouveau mot de passe',
                LABEL_SUBMIT: 'Modifier',
                ERROR: 'La confirmation du mot de passe ne correspond pas.',
                NOT_EMPTY: 'Un des mots de passe est vide.',
            }
        },
        COMPANY: {
            BASIS: {
                LABEL_NAME: 'Nom'
            },
            EMPLOYEES: {
                NEW_MEMBER: 'Ajouter un membre'
            },
            EXPERTS: {
                NEW_EXPERT: 'Ajouter un expert'
            },
            UPLOAD: {
                NO_LOGO: 'Pas de logo.',
                LABEL_CHANGE_LOGO: 'Modifier le logo',
            },
            LABEL_SUBMIT: 'Modifier',
            LABEL_SERVICES: 'Services disponibles',
            LABEL_EMPLOYEES: '{0} membre(s)',
            LABEL_EXPERTS: '{0} expert(s)',
        },
        ACCOUNT: {
            BASIS: {
                LABEL_ACCOUNT_ACTIVE: 'Le compte est activé',
                LABEL_ACCOUNT_INACTIVE: 'Le compte est désactivé',
                LABEL_COMPANY: 'Entreprise',
                CHOOSE_COMPANY: 'Choisissez une entreprise',
                LABEL_FIRSTNAME: 'Prénom',
                LABEL_LASTNAME: 'Nom',
                LABEL_EMAIL: 'Email',
                LABEL_JOB: 'Job',
            },
            CUSTOMERS: {
                LABEL_CUSTOMERS: 'Clients'
            },
            LABEL_SUBMIT: 'Enregistrer'
        }
    },

    VIEW: {
        AUTHORIZATION: {
            ACCESS_DENIED: 'Vous n\'êtes pas autorisé à accéder à cette application.',
            REDIRECTING: 'Vous allez être redirigé vers <br/><b>{0}</b>',
            ERROR: DEFAULT_ERROR,
        },
        LOGIN: {
            HEADLINE: 'Bienvenue sur votre espace client !',
            ERROR: 'Email ou mot de passe incorrect.'
        },
        FORGOTTEN_PASSWORD: {
            HEADLINE: 'Mot de passe perdu ?',
            ERROR: DEFAULT_ERROR,
            SEND: 'Vous avez reçu un lien par email pour réinitialiser votre mot de passe.'
        },
        ACCOUNT: {
            ADD: {
                HEADLINE: 'Nouvel utilisateur',
                ERROR: DEFAULT_ERROR,
            },
            EDIT: {
                CONFIRM_DELETE: 'Êtes-vous sûr de vouloir supprimer l\'utilisateur {0} ?',
                SUCCESS: 'Enregistré',
                ERROR: DEFAULT_ERROR,
            },
            EMAIL: 'Compte',
            FIRSTNAME: 'Prénom',
            LASTNAME: 'Nom',
            POSITION: 'Job',
            COMPANY: 'Entreprise',
            IS_ACTIVE: 'Status',
            ACCOUNT_ACTIVE: 'Le compte est actif',
            ACCOUNT_INACTIVE: 'Le compte est inactif',
        },
        COMPANY: {
            ADD: {
                ERROR: DEFAULT_ERROR,
                HEADLINE: 'Nouvelle entreprise'
            },
            EDIT: {
                CONFIRM_DELETE: 'Êtes-vous sûr de vouloir supprimer l\'entreprise {0} ?',
                SUCCESS: 'Enregistré',
                ERROR: DEFAULT_ERROR,
                UPLOAD_SUCCESS: 'Téléchargement terminé.',
            },
            NAME: 'Entreprises',
            NB_EMPLOYEES: 'Membres',
        },
        PROFILE: {
            CHANGE_PASSWORD: {
                SUCCESS: 'Enregistré',
                ERROR: DEFAULT_ERROR,
            },
            EDIT: {
                SUCCESS: 'Enregistré',
                ERROR: DEFAULT_ERROR,
            }
        },
        HOME: {
            LABEL_CUSTOMERS: 'Vos <b>{0}</b> clients',
            LABEL_EXPERTS: 'Nos <b>{0}</b> experts à votre service',
            LABEL_EMPLOYEES: 'Les <b>{0}</b> membres de <b>{1}</b>',
            LABEL_SERVICES: 'Nos outils',
            LABEL_MORE_DETAILS: 'Voir plus'
        }
    },

    ROUTE: {
        PROFILE: {
            LABEL_CHANGE_PASSWORD: 'Modifier votre mot de passe.',
            TITLE: 'Votre profil',
            CHANGE_PASSWORD: {
                TITLE: 'Modifier votre mot de passe',
            }
        },
        ACCOUNT: {
            NEW_ACCOUNT: 'Nouveau',
            TITLE: 'Utilisateurs',
            ADD: {
                TITLE: 'Nouvel utilisateur',
            },
        },
        COMPANY: {
            NEW_COMPANY: 'Nouveau',
            TITLE: 'Entreprises',
            ADD: {
                TITLE: 'Nouvelle entreprise',
            },
        },
        HOME: {
            TITLE: 'Bienvenue'
        },
        AUTH: {
            AUTHORIZE: {
                TITLE: 'Demande d\'accès à vos données personnelles'
            },
            LOGIN: {
                TITLE: 'Connexion'
            },
            PASSWORD_FORGOTTEN: {
                TITLE: 'Mot de passe perdu'
            }
        }
    },

    TEMPLATE: {
        HELLO: 'Bonjour <b>{0}</b>,',
        PROFILE: 'Profile',
        ACCOUNTS: 'Utilisateurs',
        COMPANIES: 'Entreprises',
        LOGOUT: 'Déconnexion',
        NEED_HELP: 'Besoin d\'aide ?',
    },

    BREADCRUMB: {
        HOMEPAGE: 'Accueil',
        ACCOUNTS: 'Utilisateurs',
        NEW_ACCOUNT: 'Nouveau',
        COMPANIES: 'Entreprises',
        NEW_COMPANY: 'Nouveau',
        PROFILE: 'Profile',
        EDIT_PASSWORD: 'Modification du mot de passe'
    }
}