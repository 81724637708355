import {Account, Company, Identifier, Me, User} from "../../../models";
import {Breadcrumb, Cache, JwtToken, PlaceHolder} from "@boomrank/react-components";
import {useParams} from "react-router";
import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {DashboardAccountEditView} from "../../views/account/edit";
import {getTranslation} from "../../../intl";

interface Props {
    me: Me
    token: JwtToken
    cacheCompanies: Cache<Company>
    cacheUsers: Cache<User>
    cacheAccounts: Cache<Account>
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
}

export function DashboardAccountEditRoute(props: Props) {
    let location = useLocation()
    let params = useParams()
    let [account, setAccount] = useState<Account | null | undefined>(undefined)
    let [user, setUser] = useState<User | null | undefined>(undefined)

    useEffect(() => {
        document.title = ` | Boomrank.io`

        if (params.accountId) {
            let accounts = props.cacheAccounts.get(params.accountId)
            if (accounts.length > 0) {
                setAccount(accounts[0])
                let users = props.cacheUsers.get(accounts[0].user)
                if (users.length > 0) {
                    setUser(users[0])
                    document.title = `${users[0].firstname} ${users[0].lastname} | Boomrank.io`
                }
            } else {
                setUser(null)
                setAccount(null)
            }
        }
    }, [params.accountId, props.cacheAccounts, props.cacheUsers])

    if (account === null || user === null) {
        return <>Error</>
    }
    if (account === undefined || user === undefined) {
        return <PlaceHolder className={'h-32'} />
    }

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content:<i className={'fa-solid fa-home'}/>, title: getTranslation('BREADCRUMB.HOMEPAGE')},
                    {to: '/dashboard/accounts/', content: getTranslation('BREADCRUMB.ACCOUNTS')},
                    {to: `/dashboard/accounts/edit/${account.id}/`, content: <>{user.firstname} {user.lastname}</>},
                ]}
            />
            <div className={'mt-3'}>
                <DashboardAccountEditView
                    account={account}
                    user={user}
                    me={props.me}
                    token={props.token}
                    cacheUpdater={props.cacheUpdater}
                    cacheCompanies={props.cacheCompanies}
                    cacheAccounts={props.cacheAccounts}
                />
            </div>
        </>
    )
}